/* Adaptive font size for html */
$html-font-size-xl: .83333333333vw;

$html-font-size-lg: 1.169590643274854vw;

$html-font-size-sm: 1.61290323vw;
$html-font-size-xs: 4.1vw;
// $html-font-size-xs: 1.169590643274854vw;

$grid-breakpoints: (
  m-sm: 799px,
  sm: 800px,
  m-lg: 1439px,
  lg: 1440px,
);

html {
  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: $html-font-size-xl;

    @media (min-aspect-ratio: 1744 / 805) {
      font-size: .6vw;
    }

    @media (min-aspect-ratio: 1744 / 661) {
      font-size: .5vw;
    }

    @media (min-aspect-ratio: 1744 / 561) {
      font-size: .4vw;
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'm-lg')) {
    font-size: $html-font-size-lg;

    @media (min-aspect-ratio: 1368 / 900) {
      font-size: .9vw;
    }

    @media (min-aspect-ratio: 1368 / 725) {
      font-size: .8vw;
    }

    @media (min-aspect-ratio: 1368 / 656) {
      font-size: .7vw;
    }

    @media (min-aspect-ratio: 1368 / 592) {
      font-size: .6vw;
    }

    @media (min-aspect-ratio: 1368 / 525) {
      font-size: .5vw;
    }

    @media (min-aspect-ratio: 1368 / 464) {
      font-size: .4vw;
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    font-size: $html-font-size-xs;

    @media (min-aspect-ratio: 375 / 817) {
      font-size: 3.7vw;
    }

    @media (min-aspect-ratio: 375 / 720) {
      font-size: 3.7vw;
    }

    @media (min-aspect-ratio: 375 / 677) {
      font-size: 3.5vw;
    }

    @media (min-aspect-ratio: 375 / 645) {
      font-size: 3.2vw;
    }

    @media (min-aspect-ratio: 375 / 589) {
      font-size: 2.9vw;
    }

    @media (min-aspect-ratio: 375 / 537) {
      font-size: 2.6vw;
    }

    @media (min-aspect-ratio: 375 / 481) {
      font-size: 2.4vw;
    }

    @media (min-aspect-ratio: 375 / 451) {
      font-size: 2.2vw;
    }
  }

}


@mixin set-adaptive-to-space() {
  @media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 1vw;

    @media (min-aspect-ratio: 1368 / 612) {
      font-size: .75vw;
    }

    @media (min-aspect-ratio: 1368 / 436) {
      font-size: .6vw;
    }
  }
}

@mixin set-adaptive-to-mobile-carousel() {
  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    font-size: 4vw;

    @media (min-aspect-ratio: 375 / 720) {
      font-size: 3.7vw;
    }

    @media (min-aspect-ratio: 375 / 677) {
      font-size: 3.5vw;
    }

    @media (min-aspect-ratio: 375 / 650) {
      font-size: 3.3vw;
    }

    @media (min-aspect-ratio: 375 / 600) {
      font-size: 3vw;
    }

    @media (min-aspect-ratio: 375 / 500) {
      font-size: 2.7vw;
    }

    @media (min-aspect-ratio: 375 / 470) {
      font-size: 2.3vw;
    }
  }
}

@mixin set-adaptive-to-mobile-earth-countries() {
  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    font-size: $html-font-size-xs;

    @media (min-aspect-ratio: 375 / 840) {
      font-size: 3.5vw;
    }

    @media (min-aspect-ratio: 375 / 785) {
      font-size: 3vw;
    }

    @media (min-aspect-ratio: 375 / 725) {
      font-size: 2.6vw;
    }

    @media (min-aspect-ratio: 375 / 665) {
      font-size: 2.4vw;
    }

    @media (min-aspect-ratio: 375 / 550) {
      font-size: 2vw;
    }
  }
}
