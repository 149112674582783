.zoom-in .solar-system {
  .planets-canvas {

  }
  .tooltip {
    opacity: 0 !important;
    visibility: hidden !important;
  }
}
// --------------------------------------------------------------------------------------------------------------
// Поведение контента планет
.zoom-in .solar-system {
  .planets-info {
    .planet-info {
      .left-content {
        transition: 1.5s ease .75s;
      }
      .right-content {
        .nft-skeleton {
          transition: 1.5s ease .45s;
        }
      }

      &#earthInfo {
        transition: .5s ease;
      }
    }
  }
  .planets-canvas {
    .planet-wrapper {
      .planet-earth {
        transition: .5s opacity .5s;
      }
    }
  }
}
// --------------------------------------------------------------------------------------------------------------
// Изменение фона
.zoom-in .space-background {
  .background-blend-red,
  .background-blend-red-2,
  .orbits-canvas {
    opacity: 0;
    visibility: hidden;
  }

  .background-stars {
    .star-layer-1 {
      transform: scale(1.5);
    }
    .star-layer-2 {
      transform: scale(1.8);
    }
  }
}
// --------------------------------------------------------------------------------------------------------------
// Скрытие кнопок и заголовка
.zoom-in {
  .main-page-title {
    top: -8rem;
    opacity: 0;
    visibility: hidden;
  }
  .main-page-cards {
    bottom: -9rem;
    opacity: 0;
    visibility: hidden;
  }
  .main-page-btns {
    right: -9rem;
    opacity: 0;
    visibility: hidden;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      right: 50%;
      opacity: 1;
      visibility: visible;
    }
  }
  .main-page-btns-2 {
    bottom: 2rem;
    opacity: 1;
    visibility: visible;
  }
  .comet {
    visibility: hidden;
  }
}
// --------------------------------------------------------------------------------------------------------------
// Положение планет в карусели
.zoom-in:not([class="zooming"]) {
  // Устанавливаем равноые размеры
  --planet-zoomin-size: 13rem;
  --planet-neptune-size: var(--planet-zoomin-size);
  --planet-jupiter-size: var(--planet-zoomin-size);
  --planet-earth-size: var(--planet-zoomin-size);
  --planet-mercury-size: var(--planet-zoomin-size);
  --planet-sun-size: 17rem;
  --planet-venus-size: var(--planet-zoomin-size);
  --planet-mars-size: var(--planet-zoomin-size);
  --planet-saturn-size: 18rem;
  --planet-moon-size: var(--planet-zoomin-size);
  --planet-uranus-size: 18rem;
  --planet-sun-coef-2: 1.5;

  .planets-canvas .planet-wrapper {
    &.sun .planet {
      @include size(calc(var(--planet-sun-size) * var(--planet-scaling-coef)));
      @include set-abs-position(calc(50% - ( var(--planet-sun-size) * var(--planet-scaling-coef) ) / 2));

      & ~ video {
        @include size(var(--planet-sun-size));
        @include set-abs-position(calc(50% - var(--planet-sun-size) / 2));
      }
    }
  }
  .planet {
    transition: .4s ease;
  }
  .planet-wrapper {
    left: 50%;
    top: -200%;
  }
}
// стили для боковых планет
.zoom-in.carousel .planet-wrapper .planet-body [class^="planet"] {
  transition: 1s opacity .3s ease !important;
}
.zoom-in.carousel {
  .planet-body {
    transition: .3s ease;
  }
  .planet-wrapper .planet {
    &:hover {
      ~ .planet-body, ~ video {
        transform: scale(1.05);
      }
    }
  }
}
// --------------------------------------------------------------------------------------------------------------
// Поведение анимации при нажатии на определенную планету
.zoom-in {
  --planet-sun-coef: 1.7;
  --planet-scaled-size: 22.5rem;

  --planet-zoomin-left: calc(50% + 16.7rem);
  --planet-zoomin-top: calc(50% - .4rem);

  // .planet-info {
  //   display: block;
  // }

  .planet-wrapper {
    transition: .5s ease;
  }
  .moon {
    animation-play-state: paused;
  }

  &.planet-active-neptune, &.planet-active-jupiter,
  &.planet-active-earth, &.planet-active-moon,
  &.planet-active-mercury, &.planet-active-sun,
  &.planet-active-venus, &.planet-active-mars,
  &.planet-active-saturn, &.planet-active-uranus,
  &.planet-active-tesla {
    .planet-body [class^="planet"], .sun video {
      opacity: 0;
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// амниация боковых планет, которые показывают, что на них можно нажать
@keyframes clickMe {
  from {
    transform: scale(1);
  }
  2% {
    transform: scale(1.03);
  }
  4% {
    transform: scale(.97);
  }
  6% {
    transform: scale(1.04);
  }
  8% {
    transform: scale(.97);
  }
  10% {
    transform: scale(1.05);
  }
  12% {
    transform: scale(.97);
  }
  14% {
    transform: scale(1.03);
  }
  16% {
    transform: scale(.97);
  }
  18% {
    transform: scale(1);
  }
  to {
    transform: scale(1);
  }
}
// -----------------------------------------------------------------------------------------------------
// полет нептуна -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-neptune .planets-canvas {
  --planet-neptune-size: 22rem;

  .neptune {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.25s ease;

    .planet, .planet-body {
      transition: 1s;

      [class^="planet"] {
        // opacity: .5;
        transition: 1s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-neptune {
  #neptuneInfo {
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-neptune {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.sun, &.uranus {
      .planet {
        cursor: pointer;
      }
      [class^="planet"], video {
        opacity: 1;
      }
      video {
        z-index: -1;
      }
    }

    &.uranus {
      animation: 10s clickMe 5s ease infinite;
    }
    &.sun { //next
      top: 50%;
      left: 100%;
    }
    &.uranus { //prev
      top: 50%;
      left: 0%;
    }
    &.mercury { // next next
      top: 50%;
      left: 150%;
    }
    &.saturn { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-neptune {
  // Анимация перелета планет
  .planets-info .planet-info#neptuneInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-neptune {
  .tesla {
    @include set-abs-position(calc(80% + var(--planet-tesla-left)), calc(100% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(100% + var(--planet-earth-left)), calc(80% + var(--planet-earth-top)));
    transition: 1.25s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.25s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(100% + var(--planet-moon-left)), calc(55% + var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(45% + var(--planet-jupiter-left)), calc(100% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(80% + var(--planet-mercury-left)), calc(100% + var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(100% + var(--planet-sun-left)), calc(70% + var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(100% + var(--planet-venus-left)), calc(50% + var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(45% + var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-left)), calc(80% + var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(75% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// полет земли -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-earth .planets-canvas {
  --planet-earth-size: 60vh;

  .earth {
    @include set-abs-position(60%, 50%);
    transition: 1.25s ease;
    visibility: hidden;

    .planet, .planet-body {
      transition: 1s ease;

      .planet-earth {
        opacity: 0;
      }
    }
  }
}
#earthInfo {
  top: calc(50% - 17rem);
}
.zoom-in.planet-active-earth {
  #earthInfo {
    visibility: visible;
    .toolbar-wrapper, .body {
      opacity: 0;
      transition: 1s ease, opacity .5s linear;
    }
  }
}
.zoom-in.carousel.planet-active-earth {
  .planets-info .planet-info#earthInfo {
    z-index: 1;
    .body, .toolbar-wrapper {
      opacity: 1;
      transition: 1s ease;
    }
  }
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;

    &.earth {
      opacity: 0;
    }

    &.moon, &.venus {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.moon { //next
      top: 50%;
      left: 100%;
    }
    &.venus { //prev
      top: 50%;
      left: 0%;
    }
    &.mars { // next next
      top: 50%;
      left: 150%;
    }
    &.mercury { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-earth {
  // Анимация перелета планет
  .planets-info .planet-info#earthInfo {
    .toolbar-wrapper, .body {
      opacity: 1 !important;
      transition: 1s ease .5s !important;
    }
  }
}
.zoom-in.zooming.planet-active-earth {
  .tesla {
    @include set-abs-position(calc(80% + var(--planet-tesla-left)), calc(100% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(25% - var(--planet-neptune-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(100% + var(--planet-moon-left)), calc(100% + var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) translate(0) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(45% + var(--planet-jupiter-left)), calc(100% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(80% + var(--planet-mercury-left)), calc(100% + var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(100% + var(--planet-sun-left)), calc(70% + var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(100% + var(--planet-venus-left)), calc(50% + var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(45% + var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-left)), calc(80% + var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(75% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// полет юпитера -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-jupiter .planets-canvas {
  --planet-jupiter-size: 21rem;

  .jupiter {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.25s ease;

    .planet, .planet-body {
      transition: 1s;

      [class^="planet"] {
        transition: 1s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-jupiter {
  #jupiterInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-jupiter {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.saturn, &.mars {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.saturn { //next
      top: 50%;
      left: 100%;
    }
    &.mars { //prev
      top: 50%;
      left: 0%;
    }
    &.uranus { // next next
      top: 50%;
      left: 150%;
    }
    &.moon { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-jupiter {
  // Анимация перелета планет
  .planets-info .planet-info#jupiterInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-jupiter {
  .tesla {
    @include set-abs-position(calc(100% + var(--planet-tesla-left)), calc(70% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(0% - var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(45% + var(--planet-earth-left)), calc(0% - var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(55% + var(--planet-moon-left)), calc(0% + var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .mercury {
    @include set-abs-position(calc(100% + var(--planet-mercury-left)), calc(30% - var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(100% + var(--planet-sun-left)), calc(0% - var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(100% + var(--planet-venus-left)), calc(0% - var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(30% - var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-left)), calc(60% - var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(70% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// полет луны -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-moon .planets-canvas {
  --planet-moon-size: 22rem;

  .moon {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.25s ease !important;

    .planet, .planet-body {
      transition: 1.25s;

      [class^="planet"] {
        transition: 1.25s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-moon {
  #moonInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-moon {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.mars, &.earth {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.mars { //next
      top: 50%;
      left: 100%;
    }
    &.earth { //prev
      top: 50%;
      left: 0%;
    }
    &.jupiter { // next next
      top: 50%;
      left: 150%;
    }
    &.venus { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-moon {
  // Анимация перелета планет
  .planets-info .planet-info#moonInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-moon {
  .tesla {
    @include set-abs-position(calc(80% + var(--planet-tesla-left)), calc(100% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(25% - var(--planet-neptune-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(100% + var(--planet-earth-left)), calc(100% + var(--planet-earth-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
    }
  }
  .jupiter {
    @include set-abs-position(calc(45% + var(--planet-jupiter-left)), calc(100% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(80% + var(--planet-mercury-left)), calc(100% + var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(100% + var(--planet-sun-left)), calc(70% + var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(100% + var(--planet-venus-left)), calc(50% + var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(45% + var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-left)), calc(80% + var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(75% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// полет меркурия -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-mercury .planets-canvas {
  --planet-mercury-size: 21rem;
  --planet-zoomin-top: calc(50% - .5rem);

  .mercury {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.5s ease;

    .planet, .planet-body {
      opacity: 1;
      transition: 1.25s ease;

      [class^="planet"] {
        transition: 1.25s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-mercury {
  #mercuryInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-mercury {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.venus, &.sun {
      .planet {
        cursor: pointer;
      }
      [class^="planet"], video {
        opacity: 1;
      }
      video {
        z-index: -1;
      }
    }

    &.venus { //next
      animation: 10s clickMe 5s ease infinite;
      top: 50%;
      left: 100%;
    }
    &.sun { //prev
      top: 50%;
      left: 0%;
    }
    &.earth { // next next
      top: 50%;
      left: 150%;
    }
    &.neptune { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-mercury {
  // Анимация перелета планет
  .planets-info .planet-info#mercuryInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-mercury {
  .tesla {
    @include set-abs-position(calc(50% + var(--planet-tesla-left)), calc(100% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(25% - var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(0% - var(--planet-earth-left)), calc(0% - var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(25% + var(--planet-moon-left)), calc(0% - var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(0% - var(--planet-jupiter-left)), calc(75% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(70% + var(--planet-sun-left)), calc(0% - var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(90% + var(--planet-venus-left)), calc(0% - var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(30% - var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-left)), calc(70% - var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(75% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// полет солнца -----------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-sun .planets-canvas {
  // Получаем новый размер планеты после нажатия
  --planet-zoomin-size: 21rem;

  .sun {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.5s ease;
  }

  .planet-wrapper.sun .planet {
    transition: 1s ease, opacity .3s linear 1.5s;

    ~ video, .shadow-1 {
      transition: 1s ease, opacity .3s linear 1.5s !important;
    }
  }
}
.zoom-in.planet-active-sun {
  .sun {
    video {
      z-index: 1;
    }
  }
  .planets-canvas .planet-wrapper.sun .planet {
    @include size(calc(var(--planet-sun-size)));
    @include set-abs-position(calc(50% - var(--planet-sun-size) / 2));

    & ~ video {
      @include size(calc(var(--planet-zoomin-size) * var(--planet-sun-coef) ));
      @include set-abs-position(calc(50% - var(--planet-zoomin-size) * var(--planet-sun-coef) / 2));
    }
  }
  .sun .planet, .sun .planet-body {
    opacity: 0;
    transition: 0s ease;

    .shadow-1 {
      @include size(calc(100% * 0.6));
      background: #D43112;
      filter: blur(2rem);
      transition: .5s linear;
      z-index: -1;
    }
  }
  #sunInfo {
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-sun {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.mercury, &.neptune {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.mercury { //next
      top: 50%;
      left: 100%;
    }
    &.neptune { //prev
      top: 50%;
      left: 0%;
    }
    &.venus { // next next
      top: 50%;
      left: 150%;
    }
    &.uranus { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-sun {
  // Анимация перелета планет
  .planets-info .planet-info#sunInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-sun {
  .sun .planet, .sun video {
    transition: 2s ease;
  }
  .tesla {
    @include set-abs-position(45% - calc(var(--planet-tesla-size)), calc(100% + var(--planet-tesla-size)));
    transition: 1.5s, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: 1.5s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(var(--planet-neptune-size) * -2), 0);
    transition: .8s ease, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transform: scale(.1);
      transition: .8s ease, opacity .5s linear;
    }
  }
  .jupiter {
    @include set-abs-position(calc(var(--planet-jupiter-size) * -2), 100%);
    transition: 1s ease, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transform: scale(.1);
      transition: 1s ease, opacity .5s linear;
    }
  }
  .earth {
    @include set-abs-position(calc(var(--planet-earth-size) * -4), 50%);
    transition: 1.3s ease, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: 1.3s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(var(--planet-earth-size) * -2), calc(100% - var(--planet-earth-size) * 2));
    transition: 1.5s, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: 1.5s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(50% - var(--planet-mercury-size) * 6), calc(100% + var(--planet-mercury-size) * 4));
    transition: 1.75s, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: 1.75s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(100% + var(--planet-venus-size) * 4), 0);
    transition: 1s, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: 1s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-size) * 2), var(--planet-saturn-size));
    transition: .5s, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: .5s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-size) * 4));
    transition: 1.5s, opacity .5s linear;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: 1.5s, opacity .5s linear;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-size) * 2), 100%);
    transition: .5s;
    .planet, .planet-body [class^="planet"], .planet-body video  {
      transition: .5s, opacity .5s linear;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// венус -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-venus .planets-canvas {
  --planet-venus-size: 21rem;
  --planet-zoomin-top: calc(50% - .5rem);

  .venus {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.5s ease;

    .planet, .planet-body {
      transition: 1s;

      [class^="planet"] {
        opacity: 0;
        transition: 1s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-venus {
  #venusInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-venus {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.earth, &.mercury {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.earth { //next
      top: 50%;
      left: 100%;
    }
    &.mercury { //prev
      top: 50%;
      left: 0%;
    }
    &.moon { // next next
      top: 50%;
      left: 150%;
    }
    &.sun { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-venus {
  // Анимация перелета планет
  .planets-info .planet-info#venusInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-venus {
  .tesla {
    @include set-abs-position(calc(40% - var(--planet-tesla-left)), calc(100% + var(--planet-tesla-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(35% + var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(0% - var(--planet-earth-left)), calc(50% + var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(0% - var(--planet-moon-left)), calc(35% - var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(0% - var(--planet-jupiter-left)), calc(100% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(10% - var(--planet-sun-left)), calc(60% + var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(25% - var(--planet-mercury-left)), calc(100% + var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(35% - var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(75% + var(--planet-mars-left)), calc(100% + var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(100% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// марс -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-mars .planets-canvas {
  --planet-mars-size: 21rem;
  // --planet-zoomin-top: calc(50% - .35rem);

  .mars {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.5s ease;

    .planet, .planet-body {
      transition: 1s;

      [class^="planet"] {
        transition: 1s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-mars {
  #marsInfo {
    opacity: 1;
    visibility: visible;

    .left-content {
      opacity: 1;
      visibility: visible;
    }

    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-mars {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.jupiter, &.moon {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.jupiter { //next
      top: 50%;
      left: 100%;
    }
    &.moon { //prev
      top: 50%;
      left: 0%;
    }
    &.saturn { // next next
      top: 50%;
      left: 150%;
    }
    &.earth { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-mars {
  // Анимация перелета планет
  .planets-info .planet-info#marsInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-mars {
  .tesla {
    @include set-abs-position(calc(0% - var(--planet-tesla-left)), calc(50% - var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(25% - var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(0% - var(--planet-earth-left)), calc(25% - var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(0% - var(--planet-moon-left)), calc(0% - var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(0% - var(--planet-jupiter-left)), calc(70% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(0% - var(--planet-sun-left)), calc(0% - var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(0% - var(--planet-mercury-left)), calc(50% - var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(90% + var(--planet-saturn-left)), calc(0% - var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(45% - var(--planet-mars-left)), calc(0% - var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(75% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// сатурн -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-saturn .planets-canvas {
  --planet-saturn-size: 33.5rem;
  --planet-zoomin-top: calc(50% - .6rem);
  --planet-zoomin-left: calc(50% + 16.75rem);

  .saturn {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.5s ease;

    .planet, .planet-body {
      transition: 1s;

      [class^="planet"] {
        transition: 1s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-saturn {
  #saturnInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-saturn {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.uranus, &.jupiter {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.uranus { //next
      top: 50%;
      left: 100%;
    }
    &.jupiter { //prev
      top: 50%;
      left: 0%;
    }
    &.neptune { // next next
      top: 50%;
      left: 150%;
    }
    &.mars { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-saturn {
  // Анимация перелета планет
  .planets-info .planet-info#saturnInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-saturn {
  .tesla {
    @include set-abs-position(calc(30% - var(--planet-tesla-left)), calc(100% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(30% + var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(0% - var(--planet-earth-left)), calc(50% + var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(0% - var(--planet-moon-left)), calc(35% + var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(0% - var(--planet-jupiter-left)), calc(90% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(10% - var(--planet-sun-left)), calc(65% + var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(0% - var(--planet-mercury-left)), calc(100% + var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(0% - var(--planet-saturn-left)), calc(48% + var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(55% - var(--planet-mars-left)), calc(100% + var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(75% + var(--planet-uranus-left)), calc(100% + var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// Уран -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-uranus .planets-canvas {
  --planet-uranus-size: 34rem;
  --planet-zoomin-top: calc(50% - .3rem);
  --planet-zoomin-left: calc(50% + 16.75rem);

  .uranus {
    @include set-abs-position(var(--planet-zoomin-left), var(--planet-zoomin-top));
    transition: 1.5s ease;

    .planet, .planet-body {
      transition: 1s;

      [class^="planet"] {
        transition: 1s ease, opacity .3s linear 1.5s;
      }
    }
  }
}
.zoom-in.planet-active-uranus {
  #uranusInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-uranus {
  // Положение планет по краям
  .planet-wrapper {
    transition: 0s;
    &.neptune, &.saturn {
      animation: 10s clickMe 5s ease infinite;
      .planet {
        cursor: pointer;
      }
      [class^="planet"] {
        opacity: 1;
      }
    }

    &.neptune { //next
      top: 50%;
      left: 100%;
    }
    &.saturn { //prev
      top: 50%;
      left: 0%;
    }
    &.sun { //next next
      top: 50%;
      left: 150%;
    }
    &.jupiter { //prev prev
      top: 50%;
      left: -50%;
    }
  }
}
.zoom-in.carousel.scrolling.planet-active-uranus {
  // Анимация перелета планет
  .planets-info .planet-info#uranusInfo {
    .right-content video {
      opacity: 1;
      transition: .9s ease .4s !important;
    }
    .left-content {
      opacity: 1;
      transition: .7s ease .6s !important;
    }
  }
}
.zoom-in.zooming.planet-active-uranus {
  .tesla {
    @include set-abs-position(calc(0% - var(--planet-tesla-left)), calc(30% + var(--planet-tesla-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(25% - var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(0% - var(--planet-earth-left)), calc(30% - var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(0% - var(--planet-moon-left)), calc(20% - var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(0% - var(--planet-jupiter-left)), calc(30% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(0% - var(--planet-sun-left)), calc(25% - var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(0% - var(--planet-mercury-left)), calc(50% - var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(30% - var(--planet-venus-left)), calc(0% - var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(0% - var(--planet-mars-left)), calc(35% - var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(75% - var(--planet-saturn-left)), calc(0% - var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// Тесла -------------
// -----------------------------------------------------------------------------------------------------
.zoom-in.planet-active-tesla .planets-canvas {
  --planet-tesla-size: 34rem;
  --planet-tesla-top: calc(20% - var(--planet-tesla-size));
  --planet-tesla-left: calc(100% + var(--planet-tesla-size));

  .tesla {
    @include set-abs-position(var(--planet-tesla-left), var(--planet-tesla-top));
    transition: 3s ease;

    .engine-shadow {
      visibility: hidden;
    }

    .planet, .planet-body {
      transition: 3s;

      [class^="planet"] {
        transition: 1s ease, opacity .3s linear;
      }
    }
  }
}
.zoom-in.planet-active-tesla {
  #teslaInfo {
    opacity: 1;
    visibility: visible;
    .left-content {
      opacity: 1;
      visibility: visible;
    }
    video {
      opacity: 1 !important;
      transform: translateX(-50%) scale(1);
    }
  }
}
.zoom-in.carousel.planet-active-tesla {
  // Положение планет по краям
  //.planet-wrapper {
  //  transition: 0s;
  //  &.neptune, &.saturn {
  //    animation: 10s clickMe 5s ease infinite;
  //    .planet {
  //      cursor: pointer;
  //    }
  //    [class^="planet"] {
  //      opacity: 1;
  //    }
  //  }
  //
  //  &.neptune { //next
  //    top: 50%;
  //    left: 100%;
  //  }
  //  &.saturn { //prev
  //    top: 50%;
  //    left: 0%;
  //  }
  //  &.sun { //next next
  //    top: 50%;
  //    left: 150%;
  //  }
  //  &.jupiter { //prev prev
  //    top: 50%;
  //    left: -50%;
  //  }
  //}
}
.zoom-in.zooming.planet-active-tesla {
  .neptune {
    @include set-abs-position(calc(0% - var(--planet-neptune-left)), calc(25% - var(--planet-neptune-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .earth {
    @include set-abs-position(calc(20% - var(--planet-earth-left)), calc(0% - var(--planet-earth-top)));
    transition: 1.5s ease;
    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .moon {
    @include set-abs-position(calc(25% - var(--planet-moon-left)), calc(0% - var(--planet-moon-top)));
    transition: 1.5s ease !important;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .3s ease !important;
      transform: scale(.1) !important;
    }
  }
  .jupiter {
    @include set-abs-position(calc(0% - var(--planet-jupiter-left)), calc(60% + var(--planet-jupiter-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .sun {
    @include set-abs-position(calc(50% + var(--planet-sun-left)), calc(0% - var(--planet-sun-left)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mercury {
    @include set-abs-position(calc(25% - var(--planet-mercury-left)), calc(0% - var(--planet-mercury-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .venus {
    @include set-abs-position(calc(75% + var(--planet-venus-left)), calc(0% - var(--planet-venus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .mars {
    @include set-abs-position(calc(100% + var(--planet-mars-left)), calc(45% - var(--planet-mars-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .saturn {
    @include set-abs-position(calc(100% + var(--planet-saturn-left)), calc(25% - var(--planet-saturn-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
  .uranus {
    @include set-abs-position(calc(100% + var(--planet-uranus-left)), calc(70% - var(--planet-uranus-top)));
    transition: 1.5s ease;

    .planet, .planet-body [class^="planet"], .planet-body video, .planet ~ video  {
      transition: 1.5s ease, opacity .5s ease;
      transform: scale(.1);
    }
  }
}
// ------------------------------
// Скрытие контента планеты пока мы летим до него
.zoom-in.zooming {
  .planet-info {
    opacity: 0 !important;
    visibility: hidden !important;
  }

  .shadow-1 {
    opacity: 0;
    visibility: hidden;
  }
}
// ------------------------------
// Скрытие планет при переходе из zoom-in to zoom-out
.zoom-out.zooming {
  .planets-canvas {
    .planet-wrapper {
      opacity: 0;
      transition: 0s ease;

      video, .shadow-1 {
        opacity: 0;
        transition: 0s ease;
      }
    }
  }
  .space-background {
    .background-blend-red {
      opacity: 0;
      transition: 0s ease;
    }
  }
}
.zoom-out.carousel {
  .planet-wrapper {
    [class^="planet"] {
      transition: 0s;
      opacity: 0;
    }
  }
}
// -----------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------
// Переходы карусели
.zoom-in.carousel {
  .solar-system .planets-info .planet-info {
    #earthInfo .body, .left-content {
      transition: 0s;
    }
    .right-content video {
      transition: 1s ease, opacity 0s ease;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        transition: .7s ease;
      }
    }
  }
  .planets-canvas .planet-wrapper .planet ~ video {
    transition: .4s ease !important;
  }
}
.zoom-in.planets-opacity {
  .planets-canvas {
    .planet-wrapper {
      transition: .7s ease !important;
    }
    .planet-wrapper [class^="planet"], .planet ~ video {
      transition: .3s ease !important;
      // transition: 0s !important;
      opacity: 1 !important;
    }
  }
}
.zoom-in.carousel.scrolling {
  .background-stars {
    .star-layer-1 {
      animation: 1.3s starsZoom1 forwards;

    }
    .star-layer-2 {
      animation: 1.3s starsZoom2 forwards;
    }
  }

  // .planets-canvas .planet-wrapper {
  //   .planet-body, video {
  //     opacity: .3 !important;
  //     cursor: default !important;
  //   }
  // }

  .solar-system .planets-info .planet-info {
    .left-content {
      opacity: 0;
      transition: .2s ease;
    }
    &#earthInfo .toolbar-wrapper, &#earthInfo .body {
      opacity: 0;
      transition: .2s ease;
    }
    .right-content video {
      // transform: translateX(-50%) scale(1);
      // transition: 0s ease;
    }
  }
}

@keyframes starsZoom1 {
  from {
    transform: scale(1.5);
  }
  35% {
    transform: scale(1.3);
  }
  to {
    transform: scale(1.5);
  }
}
@keyframes starsZoom2{
  from {
    transform: scale(1.8);
  }
  35% {
    transform: scale(1.5);
  }
  to {
    transform: scale(1.8);
  }
}
// -----------------------------------------------------------------------------------------------------
// -----------------------------------------------------------------------------------------------------
// Переход к nft страны
// .zoom-in .countries-info .country-info {
//   .right-content .nft-skeleton {
//     transition: 1s ease;
//   }
// }
$countries-names: 'USA','CHN','JPN','DEU','IND','GBR','FRA','ITA','BRA','CAN','RUS','KOR','AUS','ESP','MEX','IDN','NLD','SAU','TUR','CHE','POL','THA','BEL','SWE','IRN','NGA','ARG','AUT','ARE','NOR','ISR','IRL','PHL','SGP','HKG','MYS','ZAF','DNK', 'COL', 'EGY', 'BGD', 'CHL', 'PAK', 'FIN', 'VNM', 'CZE', 'ROU', 'PRT', 'IRQ', 'PER', 'GRC', 'NZL', 'KAZ', 'QAT', 'DZA', 'HUN', 'UKR', 'KWT', 'MAR', 'ECU', 'SVK', 'PRI', 'CUB', 'ETH', 'KEN', 'DOM', 'AGO', 'LKA', 'GTM', 'OMN', 'MMR', 'LUX', 'BGR', 'GHA', 'PAN', 'TZA', 'BLR', 'CRI', 'HRV', 'CIV', 'UZB', 'URY', 'LTU', 'SVN', 'MAC', 'LBY', 'LBN', 'SRB', 'COD', 'AZE', 'JOR', 'BOL', 'TKM', 'CMR', 'TUN', 'BHR', 'PRY', 'UGA', 'LVA', 'EST', 'NPL', 'SDN', 'SSDN', 'KHM', 'SLV', 'HND', 'CYP', 'PNG', 'TTO', 'ISL', 'SEN', 'ZMB', 'YEM', 'ZWE', 'BIH', 'AFG', 'BWA', 'LAO', 'GEO', 'MLI', 'GAB', 'JAM', 'PSE', 'BFA', 'MOZ', 'ALB', 'MLT', 'BEN', 'HTI', 'MDG', 'MUS', 'MNG', 'ARM', 'BHS', 'BRN', 'NER', 'MKD', 'NIC', 'NAM', 'GIN', 'COG', 'MDA', 'TCD', 'GNQ', 'RWA', 'KGZ', 'TJK', 'XKX', 'MWI', 'MRT', 'IMN', 'BMU', 'MCO', 'LIE', 'GUM', 'MDV', 'MNE', 'CYM', 'FJI', 'TGO', 'BRB', 'GUY', 'SWZ', 'SLE', 'VIR', 'SUR', 'DJI', 'AND', 'FRO', 'CUW', 'LBR', 'ABW', 'GRL', 'BDI', 'BTN', 'LSO', 'CAF', 'LCA', 'TLS', 'CPV', 'BLZ', 'GMB', 'SYC', 'ATG', 'SMR', 'SLB', 'GNB', 'MNP', 'GRD', 'TCA', 'SXM', 'COM', 'KNA', 'VUT', 'WSM', 'VCT', 'ASM', 'DMA', 'TON', 'STP', 'FSM', 'PLW', 'MHL', 'KIR', 'NRU', 'TUV', 'DST';

@each $country-name in $countries-names {
  .zoom-in.country-active-#{$country-name} {
    .planets-canvas,
    .planets-info,
    .planets-info .left-content,
    .planets-info .right-content,
    .planets-info .planet-info#earthInfo .toolbar-wrapper,
    .planets-info .planet-info#earthInfo .body,
    .planets-info .planet-info#earthInfo .earth-continents {
      transition: .5s ease !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }

    .back-content-btn[data-close-country]  {
      opacity: 1;
      visibility: visible;
      transition: 0s ease;
    }
    .back-content-btn[data-mve-out]  {
      opacity: 0;
      visibility: hidden;
      transition: 0s ease;
    }

    .countries-info .country-info##{$country-name}Info {
      visibility: visible;
      .left-content {
        opacity: 1;
        visibility: visible;
        transition: 1s ease;
      }

      .right-content video {
        transition: 1s ease;
        opacity: 1;
        transform: translateX(-50%) scale(1);

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          @include size(20rem, auto);
          @include set-abs-position(calc(50% + 8rem), 19.5rem);
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

    .background-stars {
      .star-layer-1 {
        transform: scale(2.1);
      }
      .star-layer-2 {
        transform: scale(2.4);
      }
    }
  }

  .root.transaction-confirmed-#{$country-name} .countries-info .country-info##{$country-name}Info {
    .right-content video {
        transition: .4s ease !important;
        transform: translateX(-50%) scale(1.1) !important;
        z-index: 11;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          transform: translateX(-50%) scale(.9) !important;
          @include set-abs-position(50%, 10rem);

          @media (min-aspect-ratio: 375 / 760) {
            transform: translateX(-50%) scale(.8) !important;
            top: 8rem;
          }

          @media (min-aspect-ratio: 375 / 710) {
            transform: translateX(-50%) scale(.7) !important;
            top: 6rem;
          }
        }
    }
  }
}

// ----------------------------------------------------------------------------------------------
// Стили на мобилке
@media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
  // $planets-names указаны в planets.scss
  .root.zoom-in {
    --planet-zoomin-size: 10rem;
    --planet-scaled-size: var(--planet-zoomin-size);
    --planet-sun-coef: 1.2;
    --planet-sun-coef-2: var(--planet-sun-coef);
  }
  .root.zoom-in.carousel {
    .back-content-btn[data-move-out]  {
      opacity: 1;
      visibility: visible;
      transition: 1s ease .3s;
    }
  }
  .root.zoom-in .planet-wrapper {
    .card-planet, .planet, .planet-body, video {
      opacity: 0 !important;
    }
  }
  @each $planet-name in $planets-names {
    .root.zoom-in.planet-active-#{$planet-name} {
      .planet-wrapper .planet {
        visibility: hidden;
      }
      @if ($planet-name == 'earth') {
          ##{$planet-name}Info {
            .toolbar-wrapper, .body {
              transition: .3s ease !important;
            }
          }
          .planet-wrapper.#{$planet-name} {
            display: block;
            // visibility: visible;
            @include set-abs-position(50%, 45%);
            .planet-body {
              @include size(30em);
              left: calc(50% - 30em / 2) !important;
              top: calc(50% - 30em / 2) !important;
              transition: 1s ease;
            }
          }
        } @else if ($planet-name == 'sun') {
          .planet-wrapper.#{$planet-name} {
            display: block;
            // visibility: visible;
            @include set-abs-position(calc(50% + 8rem), 20.5rem);
            video {
              width: 17rem !important;
              height: 17rem !important;
              left: calc(50% - 17rem / 2) !important;
              top: calc(50% - 17rem / 2 - .5rem) !important;
            }
          }
        } @else if ($planet-name == 'tesla') {
          .planet-wrapper.#{$planet-name} {
            display: block;
            @include set-abs-position(calc(100% + 18rem), 20rem);
            transition: 1s ease;

            .planet-body, .planet-body [class^="planet"] {
              transition: 1s ease,  .3s opacity linear 2s;
              opacity: 0 !important;
            }
          }
        } @else {
          .planet-wrapper.#{$planet-name} {
            --planet-#{$planet-name}-size: 12rem;
            --planet-saturn-size: 18rem;
            --planet-uranus-size: 18rem;

            @include set-abs-position(calc(50% + 8.1rem), 20.25rem);
            transition: 1s ease;
            display: block;
            // visibility: visible;
            // opacity: .5 !important;

            .planet-body, .planet-body [class^="planet"] {
              transition: 1s ease,  .3s opacity linear 2s;
              opacity: 0 !important;
            }

            .planet {
              left: calc(50% - var(--planet-#{$planet-name}-size) / 2) !important;
              top: calc(50% - var(--planet-#{$planet-name}-size) / 2) !important;
            }
          }
        }
        ##{$planet-name}Info {
          video {
            transform: translate(-50%, -50%) scale(1);
          }
        }
      }
    }
}
