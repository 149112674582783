.popup.popup-transaction {
  transform: translateY(0);

  &.show {
    transition: .5s ease;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      background: rgba(0,0,0,.9);
    }
  }

  .popup-container {
    background: none;
    border-radius: 0;
    border: none;
    padding: 0;
    box-shadow: none;
    @include size(66.625rem, auto);
    @include set-abs-position(50%);
    transform: translate(-50%, -50%);
    bottom: auto;
    @include flex(center, stretch);

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      @include size(100%, 100vh);
      @include set-abs-position(0);
      padding: 0 1.5rem;
      padding-top: 6rem;
      padding-bottom: 11rem;
      transform: translate(0);
    }
  }

  .popup-close {
    position: absolute;
    top: 2rem; right: 2rem;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      top: 1.5rem;
      right: 1.5rem;
      z-index: 10;
      @include size(2.5rem);
    }
  }


  .left-content {
    flex: 1;
    max-width: 50%;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      height: 100%;
      @include flex(space-between, flex-start, column);
      max-width: 100%;
    }

    .congrats, .subtitle, .title {
      text-transform: uppercase;
    }

    .message {
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        width: 100%;
        text-align: center;
      }
      .congrats {
        font-family: 'Barlow Condensed', sans-serif;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: 0.1em;
      }

      .subtitle {
        font-family: 'Barlow Condensed', sans-serif;
        font-style: normal;
        font-size: .875rem;
        line-height: 1.25rem;
        font-weight: 500;
        letter-spacing: 0.265em;
        opacity: .6;
        margin-top: .25rem;
      }
    }

    .planet-name {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 5rem;
      line-height: 6.25rem;
      font-weight: 500;
      margin-top: 1.5625rem;
      text-transform: capitalize;
      margin-left: -.5rem;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        font-size: 4rem;
        line-height: 4rem;
        margin-top: 1rem;
        text-overflow: ellipsis;
        margin-left: 0;
        width: 100%;
        text-align: center;
      }
    }

    .social {
      margin-top: 3rem;
      @include flex(flex-start, flex-start, column);

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        margin-top: auto;
        width: 100%;
      }

      .title {
        font-family: 'Barlow Condensed', sans-serif;
        font-style: normal;
        font-size: 1.5rem;
        line-height: 2rem;
        font-weight: 500;
        letter-spacing: 0.1em;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          font-size: 1.25rem;
          line-height: 1.25rem;
          width: 100%;
          text-align: center;
        }
      }

      .btns {
        @include flex;
        margin-top: .75rem;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          width: 100%;
        }

        .btn {
          @include size(5rem);

          @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
              @include size(3rem);
          }

          &:first-child {
            margin-left: -.5rem;
          }
        }
      }
    }
  }

  .right-content {
    width: 50%;
    position: relative;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      width: auto;
    }

    img {
      position: absolute;
      @include set-abs-position(-10%);
      height: 120%;
      z-index: -1;
    }
  }
}
