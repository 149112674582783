.main-page {
  &-title {
    @include flex(flex-start, center, column);
    position: absolute;
    width: 100%;
    top: 9rem;
    transition: .3s ease, opacity .15s ease;
    box-sizing: border-box;
    z-index: 1;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      top: 6rem;
      padding: 0 1rem;
    }

    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 2.5rem;
      font-weight: 500;
      letter-spacing: .03em;


      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include font-poppins-28-28-500;
        line-height: 2.5rem;
        text-align: center;
      }
    }

    p {
      @include font-barlow-24-24-500-32;
      color: $color-purple-500;
      margin-top: 0.5rem;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include font-barlow-14-20-600-11;
        margin-top: .5rem;
      }
    }
  }


  &-cards {
    @include flex();
    @include size(auto);
    position: absolute;
    left: 50%; bottom: 0;
    transform: translateX(-50%);
    padding-bottom: 2.5rem;
    z-index: 1;
    transition: .3s ease, opacity .15s ease;
    box-sizing: border-box;

    .card {
      &:not(:first-child) {
        margin-left: 2rem;
      }
    }
  }


  &-btns {
    position: absolute;
    right: 3rem;
    top: 50%;
    transform: translateY(-50%);
    @include flex(center, center, column);
    z-index: 1;
    transition: .3s ease, opacity .15s ease;

    a {
      display: block;
      @include size(3.5rem);
    }

    a:not(:first-child) {
      margin-top: 1rem;
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      right: 50%;
      top: auto;
      bottom: 10rem;
      transform: translate(50%, 0);
      flex-direction: row;

      a {
        @include size(2.5rem);
      }

      a:not(:first-child) {
        margin-top: 0;
        margin-left: 1rem;
      }
    }
  }

  &-btns-2 {
    position: absolute;
    left: 50%;
    bottom: -10rem;
    transform: translateX(-50%);
    @include flex(center, center);
    z-index: 1;
    transition: .3s ease, opacity .15s ease;
    opacity: 0;
    visibility: hidden;

    a {
      display: block;
      @include size(3.5rem);
    }

    a:not(:first-child) {
      margin-left: 1rem;
    }
  }
}

.offer-card {
  @include flex(center, center, column);

  .offer {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-size: .875rem;
    font-weight: 300;
    letter-spacing: .1em;
    text-align: center;
    margin-bottom: .75rem;
    text-transform: uppercase;

    > b {
      color: $color-purple-300;
      font-weight: 600;
    }
  }

  .card {
    padding: 1.25rem 2.5rem;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      padding: 0;
      background: none;
      box-shadow: none;
    }

    label {
      font-family: 'Barlow Condensed', sans-serif;
      font-style: normal;
      font-size: .875rem;
      line-height: 2rem;
      font-weight: 600;
      letter-spacing: 0.115em;
      color: $color-purple-200;
    }
    .custom-input {
      @include flex(flex-start, flex-start, column);
    }
    .validated {
      input:invalid {
        border-bottom-color: $color-red-2;
      }
    }
    form {
      @include flex;
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      }
    }
    input {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-size: 1.25rem;
      font-weight: 600;

      border-radius: 0;
      border-color: transparent;
      border-bottom-color: $color-purple-200;
      padding: 0;
      padding-bottom: .25rem;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        width: 100%;
        font-size: 1rem;
      }
    }
    .custom-input {
      position: relative;
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        width: 100%;
      }
    }
    .btn {
      width: 7.875rem;
      @include font-poppins-16-16-500;
      padding: 1rem 1.375rem;
      margin-left: 1.5rem;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        width: 8rem;
      }
    }
  }
}
