.switch-checked {
  .planets-info {
    .planet-info#earthInfo {
      .body {
        opacity: 0 !important;
        transition: .5s ease !important;
      }
    }
  }
}
.planets-info {
  .planet-info#earthInfo {
    @include set-abs-position(50%);
    transform: translate(-50%, -50%);
    // width: 100%;
    height: auto;
    visibility: visible;
    z-index: -1;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      @include set-abs-position(50%, 0);
      transform: translate(-50%, 0);
      height: 100vh;
    }

    .toolbar-wrapper {
      position: absolute;
      left: calc(50% - 66.625rem / 2); top: 0;
      width: 66.625rem;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include size(100%, auto);
        @include set-abs-position(0);
        margin-top: 6rem;
      }
    }

    .toolbar-wrapper, .body {
      opacity: 0;
      z-index: 6;
    }

    .body {
      margin-top: 6rem;
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include size(100%, 50vh);
        margin-top: 10rem;
      }
    }

    .map {
      @include size(100%, 60vh);
      position: relative;
      overflow: hidden;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include size(100%);
      }

      svg, img {
        position: absolute;
        @include size(120%, auto);
        @include set-abs-position(50%);
        transform: translate(-50%, -50%);
        transition: .5s ease;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          @include size(120%, auto);
        }
      }

      img {
        @supports (mix-blend-mode: lighten) {
          mix-blend-mode: lighten;
        }
      }

      svg {
        z-index: 1;
        g {
          cursor: pointer;
          path, polygon {
            fill: rgba($color-purple-400, 0);
            stroke-width: 2px;
            transition: .1s ease;
          }

          &:hover {
            > path, > polygon {
              fill: rgba($color-purple-400, .9);
            }
          }
        }

        g.sold {
          path, polygon {
            fill: rgba(#191428, .7);
          }
          &:hover {
            > path, > polygon, > g > path, > g > polygon {
              fill: rgba(#191428, .9);
            }
          }
        }
      }
    }
  }
}


.st0{fill:#878787;stroke:#F9F9F9;stroke-width:0.15;stroke-linecap:round;stroke-linejoin:round;}

    .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#878787;stroke:#F9F9F9;stroke-width:0.15;stroke-linecap:round;stroke-linejoin:round;}
.st2{display:none;}
.st3{display:inline;fill:#FFE3CA;stroke:#999999;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;}

    .st4{display:inline;fill-rule:evenodd;clip-rule:evenodd;fill:#FCD4C7;stroke:#999999;stroke-width:0.25;stroke-linecap:round;stroke-linejoin:round;}
.st5{display:inline;fill:#878787;stroke:#F9F9F9;stroke-width:0.15;stroke-linecap:round;stroke-linejoin:round;}
.st6{fill:none;stroke:#F9F9F9;stroke-width:0.15;stroke-linecap:round;stroke-linejoin:round;}
