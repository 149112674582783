@import 'colors';
@import 'mixins';
@import 'grid';
@import 'fonts';

html {
  font-family: 'Poppins', sans-serif;
  color: white;
}

* {
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;

  &.fixed {
    position: fixed;
    @include size(100vw, 100vh);
    overflow: hidden;
  }
}

input, textarea {
  font-family: 'Poppins', sans-serif;
}

a {
  text-decoration: none;
}

@import 'unusual-elements';
@import 'components/buttons/buttons';
@import 'components/forms/forms';
@import 'components/tooltips/tooltips';
@import 'components/alerts/alerts';
@import 'components/cards/cards';
@import 'components/params/params';
@import 'components/popup/popup';
@import 'components/range-slider/range-slider';
@import 'components/pocket/pocket';
@import 'components/metamask/metamask';
@import 'components/map/map';

@import 'templates/header';
@import 'templates/main-page';
@import 'templates/background';
@import 'templates/planets';
@import 'templates/switch-distance';
@import 'templates/planets-info';
@import 'templates/earth-continents';
@import 'templates/about-us';
@import 'templates/preloader';
@import 'templates/countries-info';
@import 'templates/rotated-screen';

@import 'animation';

.root {
  @include size(100%);
}

.solar-system {
  @include size(100%);
  overflow: hidden;
  position: relative;

  &.active-tab {
    display: block;
    opacity: 1;
  }
}


.loading {
  .modals, .main-page-title,
  .main-page-cards, .main-page-btns,
  .about-us, .modals,
  .orbits-canvas, .comet,
  .background-blend-red,
  .background-blend-red-2,
  header, video, .card-planet,
  .planet-body [class^="planet"],
  .engine-shadow,
  .solar-system {
    opacity: 0 !important;
    transition: 0s !important;
    visibility: hidden !important;
  }


  video, .planet-body [class^="planet"] {
    transform: scale(.9);
  }
}

.preloading {
  .modals, .main-page-title,
  .main-page-cards, .main-page-btns,
  .about-us, .modals,
  .orbits-canvas, .comet,
  [class^="background-blend"],
  header, video, .card-planet,
  .planet-body [class^="planet"],
  .engine-shadow {
    transition: .5s linear !important;
  }
}

#body {
  width: 100%;
  height: 100%;
}
