.btn-box {
  @include size(3rem);
  transition: .3s ease;
  opacity: .72;

  @each $key, $value in $box-button-icons {
    &.btn-#{$key} {
      background-image: url('../static/icons/buttons-box/#{$value}.svg');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
    }
  }
  @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
    &:hover {
      opacity: 1;
    }

    &:active {
      opacity: .72;
    }
  }

  &.disabled {
    opacity: .16;

    @media screen and (min-width: map-get($grid-breakpoints, 'sm')) {
      &:hover {
        opacity: .44;
      }

      &:active {
        opacity: .64;
      }
    }
  }
}

.btn.btn-box {
  mask-image: initial;
}
