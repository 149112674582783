.countries-info {
  .country-info {
    .right-content {
      .nft-skeleton {
        z-index: -1;
        transition: .3s ease;
      }
    }
  }
}
