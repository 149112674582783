.card.card-token-item {
  @include size(15.625rem, auto);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: column;
  cursor: default;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(180deg, rgba(28, 191, 241, 0.28) 0%, rgba(28, 191, 241, 0.24) 29.4%, rgba(28, 191, 241, 0.24) 78.88%);
  box-shadow: none;
  transition: .5s ease;
  border-radius: .25rem;

    .token-number {
      color: #FFADAD;
    }

    .token-name {
      color: $color-purple-500;
      text-overflow: ellipsis;
      max-width: 100%;
      overflow: hidden;
    }

    .token-price {
      background: rgba(#020710, .31);
      box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.12);
    }

    .token-price-usd {
      color: #FFADAD;
    }

    .token-sold {
      @include size(100%, 10.5rem);
      @include flex;
      display: none;
      img {
        @include size(70%, auto);
        margin-bottom: 1rem;
      }
    }

    &.sold {
      background: $card-gray-purchased-items-sold-background;
      border: none;

      .token-price {
        display: none;
      }

      .token-sold {
        display: flex;
      }

      .token-name, .token-price, .token-number, .token-image, .btn {
        opacity: .8;
        transition: .5s ease;
      }

      &:hover {
        background: linear-gradient(180deg, rgba(28, 191, 241, 0.1) 0%, rgba(28, 191, 241, 0.1) 29.4%, rgba(28, 191, 241, 0.1) 78.88%);

        > .token-price {
          background: rgba(#000000, .2);
          box-shadow: inset 0px 0px 6px rgba(0, 0, 0, .3);
        }

        > .token-name, > .token-price, > .token-number, > .token-image, > .btn {
          opacity: 1;
        }
      }
  }

  .token {
    &-image {
      @include size(9.125rem, 6.25rem);
      margin-top: -5.5rem;

      & > * {
        @include size(120%);
      }
    }

    &-number {
      @include font-poppins-20-30-600;
      letter-spacing: 1px;
      color: $color-purple-200;
    }

    &-name {
      @include font-poppins-28-28-500;
      white-space: nowrap;
      text-align: center;
      margin-top: 0.5rem;
    }

    &-emission {
      @include font-barlow-18-auto-600-4;
      color: $color-green-400;
      margin-top: 0.5rem;
    }

    &-price {
      width: 100%;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-top: 1.875rem;
      padding-bottom: 1.875rem;
      // background-color: rgba(29, 12, 64, 0.12);
      transition: .3s ease;
      border-radius: 1rem;
      text-align: center;

      .btn-etherium {
        cursor: default;
        margin-left: auto;
        margin-right: auto;
        @include size(1.6875rem)
      }

      &-eth {
        @include flex(center, center);
        margin-top: .625rem;

        b {
          @include font-poppins-16-16-600;
        }

        span {
          @include font-barlow-11-auto-600-4;
          color: rgba(255, 255, 255, .64);
          text-transform: uppercase;
          margin-left: .375rem;
          letter-spacing: 3px;
        }
      }

      &-usd {
        margin-top: 2px;
        @include font-barlow-14-auto-600-4;
        color: $color-purple-200;
      }
    }
  }

  .btn-more {
    width: 100%;
  }

  &:hover {
    background: linear-gradient(180deg, rgba(28, 191, 241, 0.3) 0%, rgba(28, 191, 241, 0.3) 29.4%, rgba(28, 191, 241, 0.3) 78.88%);
    .token {
      &-price {
        background: rgba(#020710, .4);
        box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.24);
      }
    }
  }
}
