.popup.popup-wallet {
  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    z-index: 11;
  }
  .popup-container {
    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      @include size(100%);
      border-radius: 0;
      padding: 0 1.5rem;
      padding-top: 5rem;
    }

    .popup-close {
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        top: 1.5rem;
        right: 1.5rem;
        @include size(2.5rem);
      }
    }

  }
  .wallet {
    &-header {
      display: flex;
      align-items: center;
    }

    &-avatar {
      border-radius: 2rem;
      margin-right: 1.5rem;
      overflow: hidden;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include size(3rem);
        min-width: 3rem;
        min-height: 3rem;
        margin-right: 1rem;
        border-right: 50%;
      }

      img {
        vertical-align: middle;
        display: inline-block;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          @include size(100%);
        }
      }
    }

    &-creds {
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        overflow: hidden;
        width: 100%;
      }
    }

    &-address {
      @include font-barlow-20-24-500-11;
      text-transform: uppercase;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-label {
      @include font-poppins-20-24-500;
      color: $color-purple-100;
      margin-top: .5rem;
    }

    &-data {
      display: flex;
      margin-top: 3rem;
      margin-bottom: 3rem;
      justify-content: space-between;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        margin: 1.5rem 0;
        flex-direction: column;
        justify-content: flex-start;
      }

      &-wrap {
        display: flex;
        flex-grow: 1;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          width: 100%;
          justify-content: flex-start;
        }
      }

      &-icon {
        opacity: 1;
        cursor: default;
        margin-right: 1rem;
      }

      &-label {
        @include font-barlow-14-20-600-11;
        color: $color-purple-200;
      }

      &-value {
        @include font-poppins-20-30-600;
        line-height: 1;
        color: $color-purple-50;
      }

      &-info {
        align-self: center;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          width: 100%;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $color-purple-800;
        box-sizing: border-box;
        border-radius: .75rem;
        padding: 1.5rem 1.875rem;
        flex-grow: 1;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          padding: 1rem;
          flex-direction: column;
          align-items: flex-start;

          .btn-more {
            margin-top: .5rem;
            width: 100%;
          }
        }

        &:first-child {
          flex-basis: 20.625rem;

          @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
            flex-basis: auto;
          }
        }

        &:last-child {
          flex-basis: 43.125rem;
          margin-left: 2rem;

          @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
            flex-basis: auto;
            margin-left: 0rem;
            margin-top: 1rem;
          }
        }
      }
    }

    &-owned {
      &-header {
        display: flex;
        align-items: center;
      }

      &-title {
        @include font-poppins-20-30-600;
        letter-spacing: 0.02em;
        margin-right: 0.625rem;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          width: 100%;
          white-space: nowrap;
          font-size: 1.25rem;
        }
      }

      &-arrow {
        flex-grow: 1;
        opacity: .2;
        margin-right: 0.625rem;
        display: flex;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          opacity: 1;
        }

        img {
          width: 100%;
        }
      }

      &-number {
        color: $color-white-60;
      }

      &-image {
        @include size(10rem);
        margin-left: auto;
        margin-right: auto;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &-list {
        white-space: nowrap;
        margin-top: 2rem;
        margin-bottom: 3.75rem;
        box-sizing: border-box;
        overflow-x: auto;
        @include flex(flex-start, stretch);
        max-width: 100%;
        padding-bottom: 2rem;

        &::-webkit-scrollbar-track {background: $color-purple-100;}
        &::-webkit-scrollbar {height: .25rem;}
        &::-webkit-scrollbar-thumb {border-radius: 0; background: $color-purple-600;}
        &:hover::-webkit-scrollbar-thumb {background: $color-purple-600;}

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          width: 100vw;
          max-width: 100vw;
          margin-left: -1.5rem;
          margin-top: 1rem;
          padding-left: 1.5rem;
          padding-bottom: 1.5rem;
          &::-webkit-scrollbar {display: none;}
        }
      }

      &-item,
      &-empty {
        width: 15rem;
        min-width: 15rem;
        border-radius: 1rem;
        background: linear-gradient(180deg, rgba(28, 191, 241, 0.07) 0%, rgba(28, 191, 241, 0.02) 29.4%, rgba(28, 191, 241, 0.08) 78.88%);
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding-bottom: 2rem;
        margin-right: 2rem;
        text-align: center;
        padding-top: 2rem;
        cursor: pointer;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          margin-right: 1rem;
        }

        &:last-child {
          margin-right: 1px;

          @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
            margin-right: 1.5rem;
          }
        }

        &:hover {
          background: linear-gradient(180deg, rgba(28, 191, 241, 0.15) 0%, rgba(28, 191, 241, 0.1) 29.4%, rgba(28, 191, 241, 0.12) 78.88%);
        }
      }

      &-empty {
        padding-top: 2.9375rem;
        padding-bottom: 2.9375rem;
        vertical-align: top;
        cursor: default;
      }

      &-name {
        margin-top: 1.25rem;
        @include font-poppins-20-24-500;
      }

      &-text {
        white-space: normal;
        @include font-poppins-16-16-600;
        padding-left: 2rem;
        padding-right: 2rem;
        line-height: 1.5rem;
        color: white;

        div {
          margin-top: 1.25rem;
        }
      }
    }
  }
}
