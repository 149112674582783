.metamask {
  background: rgba(#0D363F, 0.7);
  box-shadow: inset 0px 0px 5px #0F678C;
  border-radius: 2rem;
  width: 34.375rem;
  height: 31.25rem;

  &.metamask-popup {
    position: absolute;
    top: calc(50% - 31.25rem / 2);
    left: calc(50% - 34.375rem / 2);
    opacity: 0;
    display: none;
    transform: translateY(100vh);
    transition: .3s ease;
    z-index: 10;
    box-sizing: border-box;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      z-index: 11;
      padding: 2rem;
      @include size(calc(100vw - 3rem), auto);
      @include set-abs-position(1.5rem, 40%);
    }
  }

  &.show {
    transform: translateY(0);
    display: block;
    opacity: 1;
    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      transform: translateY(-50%);
    }    
  }

  .metamask-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @include size(100%);
  }

  &-title {
    @include font-poppins-28-28-500;
    font-weight: 300;
    margin: 2.5rem 0;
    text-align: center;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      margin: 2rem 0;
      font-size: 1.5rem;
    }
  }

  &-image {
    text-align: center;
    img {
      @include size(8rem);
      filter: drop-shadow(0px 42px 45px rgba(0, 0, 0, 0.12)), drop-shadow(0px 16.1778px 14.3333px rgba(0, 0, 0, 0.0728889)), drop-shadow(0px 3.42222px 3.66667px rgba(0, 0, 0, 0.0471111));
    }
  }
}
