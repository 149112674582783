:root {
  --orbit-width: 8.125rem;
  --orbit-offset: 0.125rem;
  --orbit-mobile-width: calc(100% / 3 + 100% / 6);
  --orbit-mobile-offset: .25rem;
  --planet-mobile-size: 5rem;
}

$planets-list: (
  earth: 'earth',
  jupiter: 'jupiter',
  mars: 'mars',
  mercury: 'mercury',
  moon: 'moon',
  neptune: 'neptune',
  saturn: 'saturn',
  venus: 'venus',
  uranus: 'uranus',
  tesla: 'tesla'
);

@each $key, $value in $planets-list {
  .planet-#{$key} {
    @include size(100%);
    background-image: url('../static/images/planets/#{$value}.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// ----------------------------------------------------------------------------------------------
// Холст
.switch-checked {
  .planets-canvas {
    opacity: 0;
  }
}
.planets-canvas {
  position: absolute;
  @include set-abs-position;
  @include size(100vw, 100vh);
  overflow: hidden;
  @include flex();
  transition: .3s ease;
}
// ----------------------------------------------------------------------------------------------
// Размер и вид планет
:root {
  --planet-neptune-size: 8.625em;
  --planet-jupiter-size: 11.5em;
  --planet-earth-size: 8.125em;
  --planet-moon-size: 2.625em;
  --planet-mercury-size: 3.75em;
  --planet-sun-size: 12em;
  --planet-venus-size: 4em;
  --planet-mars-size: 7.25em;
  --planet-saturn-size: 12.875em;
  --planet-uranus-size: 7.75em;
  --planet-tesla-size: 3.7125em;

  --planet-scaling-coef: 0.6;
  --planet-sun-scaling-coef: 0.4;
}
.planets-canvas {
  .planet-wrapper {
    position: absolute;

    &.neptune .planet-body { @include size(var(--planet-neptune-size)); }
    &.jupiter .planet-body { @include size(var(--planet-jupiter-size)); }
    &.earth .planet-body { @include size(var(--planet-earth-size)); }
    &.moon .planet-body { @include size(var(--planet-moon-size)); }
    &.mercury .planet-body { @include size(var(--planet-mercury-size)); }
    &.venus .planet-body { @include size(var(--planet-venus-size)); }
    &.mars .planet-body { @include size(var(--planet-mars-size)); }
    &.saturn .planet-body { @include size(var(--planet-saturn-size)); }
    &.uranus .planet-body { @include size(var(--planet-uranus-size)); }
    &.tesla .planet-body { @include size(var(--planet-tesla-size)); }

    // Размер хувер области
    &.neptune .planet { @include size(calc(var(--planet-neptune-size) * var(--planet-scaling-coef))); }
    &.jupiter .planet { @include size(calc(var(--planet-jupiter-size) * var(--planet-scaling-coef))); }
    &.earth .planet { @include size(calc(var(--planet-earth-size) * var(--planet-scaling-coef))); }
    &.moon .planet { @include size(calc(var(--planet-moon-size) * var(--planet-scaling-coef))); }
    &.mercury .planet { @include size(calc(var(--planet-mercury-size) * var(--planet-scaling-coef))); }
    &.venus .planet { @include size(calc(var(--planet-venus-size) * var(--planet-scaling-coef))); }
    &.mars .planet { @include size(calc(var(--planet-mars-size) * var(--planet-scaling-coef))); }
    &.saturn .planet { @include size(calc(var(--planet-saturn-size) * var(--planet-scaling-coef))); }
    &.uranus .planet { @include size(calc(var(--planet-uranus-size) * 0.5)); }
    &.sun .planet { @include size(calc(var(--planet-sun-size) * var(--planet-sun-scaling-coef))); }
    &.tesla .planet { @include size(calc(var(--planet-tesla-size))); }


    &.neptune .planet-body > video { @include size(var(--planet-neptune-size)); }
    &.jupiter .planet-body > video { @include size(var(--planet-jupiter-size)); }
    &.earth .planet-body > video { @include size(var(--planet-earth-size)); }
    &.moon .planet-body > video { @include size(var(--planet-moon-size)); }
    &.mercury .planet-body > video { @include size(var(--planet-mercury-size)); }
    &.sun .planet ~ video { @include size(calc(var(--planet-sun-size))); }
    &.venus .planet-body > video { @include size(var(--planet-venus-size)); }
    &.mars .planet-body > video { @include size(var(--planet-mars-size)); }
    &.saturn .planet-body > video { @include size(var(--planet-saturn-size)); }
    &.uranus .planet-body > video { @include size(var(--planet-uranus-size)); }

    &.neptune .planet-body > video, &.jupiter .planet-body > video,
    &.earth .planet-body > video, &.moon .planet-body > video,
    &.mercury .planet-body > video,
    &.venus .planet-body > video, &.mars .planet-body > video,
    &.saturn .planet-body > video, &.uranus .planet-body > video {
      position: absolute;
      opacity: 0;
      transition: .5s linear;
    }

    .planet {
      z-index: 1;
    }

    .planet, .planet-body {
      position: absolute;
      transition: .7s ease;


      [class^="planet"] {
        position: absolute;
        @include size(100%);
        @include set-abs-position;
        transition: .3s ease, opacity .8s linear;
      }

      ~ video {
        transition: .3s ease, opacity .8s linear;
      }

      .shadow-1 {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        @include set-abs-position(50%);
        @include size(50%);
        filter: blur(1.25rem);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        transition: .3s ease;
      }
    }
  }
}
// ----------------------------------------------------------------------------------------------
// Хувер планет при зумауте
.zoom-out {
  .planet {
    cursor: pointer;
  }
  .planet:hover {
    // transform: scale(1.05);
    transform: matrix3d(1.05, 0, 0, 0,
                        0, 1.05, 0, 0,
                        0, 0, 1.05, 0,
                        0, 0, 0, 1);

    ~ .planet-body {
      & [class^="planet"], video {
        // transform: scale(1.1);
        transform: matrix3d(1.1, 0, 0, 0,
                            0, 1.1, 0, 0,
                            0, 0, 1.1, 0,
                            0, 0, 0, 1);
      }

      .shadow-1 {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .sun .planet:hover {
    transform: matrix3d(1.2, 0, 0, 0,
                        0, 1.2, 0, 0,
                        0, 0, 1.2, 0,
                        0, 0, 0, 1);

    ~ video {
      transform: matrix3d(1.2, 0, 0, 0,
                          0, 1.2, 0, 0,
                          0, 0, 1.2, 0,
                          0, 0, 0, 1);
    }
  }
}
.planets-canvas .planet {
  ~ video {
    position: absolute;
    transition: .3s ease;
    z-index: -1;

    @supports (mix-blend-mode: lighten) {
      mix-blend-mode: lighten;
    }
  }
}

// ----------------------------------------------------------------------------------------------
// Размер и вид солнца
.zoom-out {
  .sun {
    .planet:hover {
      ~ video {
        opacity: 1;
        transform: matrix3d(1.1, 0, 0, 0,
                            0, 1.1, 0, 0,
                            0, 0, 1.1, 0,
                            0, 0, 0, 1);
      }
    }
  }
}
// ----------------------------------------------------------------------------------------------
// Анимация вращения луны
:root {
  --planet-moon-orbit-radius: 5em;
}
.planets-canvas {
  .moon {
    // transform: translateX(var(--planet-moon-orbit-radius));
    // animation: 30s moonRotation linear infinite;
  }
}
// @keyframes moonRotation {
//   from {
//     transform: rotate(0deg) translateX(var(--planet-moon-orbit-radius)) rotate(-0deg);
//   }
//   to {
//     transform: rotate(360deg) translateX(var(--planet-moon-orbit-radius)) rotate(-360deg);
//   }
// }
// ----------------------------------------------------------------------------------------------
// Базовая тень при хувере
.planets-canvas {
  .neptune .shadow-1 { background: rgba(0, 44, 57, 0.5); }
  .jupiter .shadow-1 { background: rgba(96, 25, 17, 0.5); }
  .earth .shadow-1 { background: rgba(0, 24, 61, .5); }
  .moon .shadow-1 { background: rgba(80, 80, 80, 0.7); }
  .mercury .shadow-1 { background: rgba(203, 167, 38, 0.3); }
  .sun .shadow-1 { background: rgba(255, 0, 0, 0.2); }
  .venus .shadow-1 { background: rgba(108, 0, 78, 0.5); }
  .mars .shadow-1 { background: rgba(73, 6, 0, 0.3); }
  .saturn .shadow-1 { background: rgba(75, 67, 49, 0.4); }
  .uranus .shadow-1 { background: rgba(74, 86, 128, 0.4); }

  .tesla .engine-shadow {
    position: absolute;
    @include size(2.5em,1.9em);
    @include set-abs-position(-.75em, .6em);
    background: url('../static/images/tesla/engine.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      visibility: hidden;
    }
  }

}
// ----------------------------------------------------------------------------------------------
// Тултип
.planets-canvas {
  .planet:hover {
    & > .tooltip, & ~ .tooltip {
      transform: translate(-50%, 0rem);
      opacity: 1;
      visibility: visible;
    }
  }
  .neptune .tooltip {bottom:calc(var(--planet-neptune-size) / 2);}
  .jupiter .tooltip {bottom:calc(var(--planet-jupiter-size) / 2);}
  .earth .tooltip {bottom:calc(var(--planet-earth-size) / 2 - .5em);}
  .moon .tooltip {bottom:calc(var(--planet-moon-size) / 2 + .5em);}
  .mercury .tooltip {bottom:calc(var(--planet-mercury-size) / 2 + .25em);}
  .sun .tooltip {bottom:calc(var(--planet-sun-size) * var(--planet-scaling-coef) / 2 + 1em);}
  .venus .tooltip {bottom:calc(var(--planet-venus-size) / 2);}
  .mars .tooltip {bottom:calc(var(--planet-mars-size) / 2 - .5em);}
  .saturn .tooltip {bottom:calc(var(--planet-saturn-size) / 2 - 2.5em);}
  .uranus .tooltip {bottom:calc(var(--planet-uranus-size) / 2 - 1.25em);}
  .tesla .tooltip {bottom:calc(var(--planet-uranus-size) / 2 - 2em);}
}
// ----------------------------------------------------------------------------------------------
// Расположение планет
:root {
  --planet-neptune-left: 50% - var(--planet-neptune-size) / 2 - 28em;
  --planet-neptune-top: 50% - var(--planet-neptune-size) - 1.5em;
  --planet-jupiter-left: 50% - var(--planet-jupiter-size) / 2 - 10.5em;
  --planet-jupiter-top: 50% + var(--planet-jupiter-size) + 3em;
  --planet-earth-left: 50% - var(--planet-earth-size) / 2 - 9.5em;
  --planet-earth-top: 50% - 3em;
  --planet-moon-left: 50% - var(--planet-moon-size) / 2 - 11.25em;
  --planet-moon-top: 50% - var(--planet-moon-size) - 2em;
  --planet-mercury-left: 50% - var(--planet-mercury-size) / 2 - 3.5em;
  --planet-mercury-top: 50% + var(--planet-mercury-size) + 2.5em;
  --planet-sun-left: 50%;
  --planet-venus-left: 50% + var(--planet-venus-size) / 2 + 6.5em;
  --planet-venus-top: 50% - var(--planet-venus-size) - 3em;
  --planet-mars-left: 50% + var(--planet-mars-size) / 2 + 14em;
  --planet-mars-top: 50% + var(--planet-mars-size) - 2em;
  --planet-saturn-left: 50% + var(--planet-saturn-size) / 2 + 17em;
  --planet-saturn-top: 50% - var(--planet-saturn-size);
  --planet-uranus-left: 50% + var(--planet-uranus-size) / 2 + 23em;
  --planet-uranus-top: 50% + var(--planet-uranus-size) + 5em;
  --planet-tesla-left: 50% - var(--planet-tesla-size) / 2;
  --planet-tesla-top: 50% + var(--planet-tesla-size) + 7.85em;
}
.planet-wrapper {
  @include set-adaptive-to-space;
}
.planets-canvas {
  // Позиционируем планету по середине planet-wrapper
  .neptune .planet-body { @include set-abs-position(calc(50% - var(--planet-neptune-size) / 2)); }
  .jupiter .planet-body { @include set-abs-position(calc(50% - var(--planet-jupiter-size) / 2)); }
  .earth .planet-body { @include set-abs-position(calc(50% - var(--planet-earth-size) / 2)); }
  .moon .planet-body { @include set-abs-position(calc(50% - var(--planet-moon-size) / 2)); }
  .mercury .planet-body { @include set-abs-position(calc(50% - var(--planet-mercury-size) / 2)); }
  .sun video { @include set-abs-position(calc(50% - var(--planet-sun-size) / 2)); }
  .venus .planet-body { @include set-abs-position(calc(50% - var(--planet-venus-size) / 2)); }
  .mars .planet-body { @include set-abs-position(calc(50% - var(--planet-mars-size) / 2)); }
  .saturn .planet-body { @include set-abs-position(calc(50% - var(--planet-saturn-size) / 2)); }
  .uranus .planet-body { @include set-abs-position(calc(50% - var(--planet-uranus-size) / 2)); }
  .tesla .planet-body { @include set-abs-position(calc(50% - var(--planet-tesla-size) / 2)); }

  // Позиционируем хувер область по середине planet-wrapper
  .neptune .planet { @include set-abs-position(calc(50% - var(--planet-neptune-size) * var(--planet-scaling-coef) / 2)); }
  .jupiter .planet { @include set-abs-position(calc(50% - var(--planet-jupiter-size) * var(--planet-scaling-coef) / 2)); }
  .earth .planet { @include set-abs-position(calc(50% - var(--planet-earth-size) * var(--planet-scaling-coef) / 2)); }
  .moon .planet { @include set-abs-position(calc(50% - var(--planet-moon-size) * var(--planet-scaling-coef) / 2)); }
  .mercury .planet { @include set-abs-position(calc(50% - var(--planet-mercury-size) * var(--planet-scaling-coef) / 2)); }
  .sun .planet { @include set-abs-position(calc(50% - var(--planet-sun-size) * var(--planet-sun-scaling-coef) / 2)); }
  .venus .planet { @include set-abs-position(calc(50% - var(--planet-venus-size) * var(--planet-scaling-coef) / 2)); }
  .mars .planet { @include set-abs-position(calc(50% - var(--planet-mars-size) * var(--planet-scaling-coef) / 2)); }
  .saturn .planet { @include set-abs-position(calc(50% - var(--planet-saturn-size) * var(--planet-scaling-coef) / 2)); }
  .uranus .planet { @include set-abs-position(calc(50% - var(--planet-uranus-size) * 0.5 / 2)); }
  .tesla .planet { @include set-abs-position(calc(50% - var(--planet-tesla-size) / 2)); }

  // Позиционируем планету на странице
  .neptune { @include set-abs-position(calc(var(--planet-neptune-left)), calc(var(--planet-neptune-top))); }
  .jupiter { @include set-abs-position(calc(var(--planet-jupiter-left)), calc(var(--planet-jupiter-top))); }
  .earth { @include set-abs-position(calc(var(--planet-earth-left)), calc(var(--planet-earth-top))); }
  .moon { @include set-abs-position(calc(var(--planet-earth-left)), calc(var(--planet-earth-top))); }
  .mercury { @include set-abs-position(calc(var(--planet-mercury-left)), calc(var(--planet-mercury-top))); }
  .sun { @include set-abs-position(calc(var(--planet-sun-left))); }
  .venus { @include set-abs-position(calc(var(--planet-venus-left)), calc(var(--planet-venus-top))); }
  .mars { @include set-abs-position(calc(var(--planet-mars-left)), calc(var(--planet-mars-top))); }
  .saturn { @include set-abs-position(calc(var(--planet-saturn-left)), calc(var(--planet-saturn-top))); }
  .uranus { @include set-abs-position(calc(var(--planet-uranus-left)), calc(var(--planet-uranus-top))); }
  .tesla { @include set-abs-position(calc(var(--planet-tesla-left)), calc(var(--planet-tesla-top))); }
}

.zoom-out .planets-canvas {
  .neptune { animation: 10s planetRotation1 linear infinite }
  .jupiter { animation: 8s planetRotation2 linear infinite }
  .mercury { animation: 20s planetRotation2 linear infinite }
  .venus { animation: 8s planetRotation1 linear infinite }
  .mars { animation: 7s planetRotation2 linear infinite }
  .saturn { animation: 15s planetRotation1 linear infinite }
  .uranus { animation: 16s planetRotation2 linear infinite }
}

@keyframes planetRotation1 {
  from {
    transform: rotate(0deg) translateX(.1rem) rotate(-0deg);
  }
  to {
    transform: rotate(360deg) translateX(.1rem) rotate(-360deg);
  }
}
@keyframes planetRotation2 {
  from {
    transform: rotate(-0deg) translateX(.1rem) rotate(0deg);
  }
  to {
    transform: rotate(-360deg) translateX(.1rem) rotate(360deg);
  }
}
// ----------------------------------------------------------------------------------------------
// Поднятие планет, если транзакция прошла
$planets-names: 'sun', 'neptune', 'earth', 'moon', 'jupiter', 'mercury', 'venus', 'mars', 'saturn', 'uranus', 'tesla';
@each $planet-name in $planets-names {
  .root.transaction-confirmed-#{$planet-name} .planets-info .planet-info##{$planet-name}Info {
    .right-content video {
        transition: .4s ease !important;
        transform: translateX(-50%) scale(1.1) !important;
        z-index: 11;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          transform: translateX(-50%) scale(.9) !important;
          @include set-abs-position(50%, 10rem);

          @media (min-aspect-ratio: 375 / 760) {
            transform: translateX(-50%) scale(.8) !important;
            top: 8rem;
          }

          @media (min-aspect-ratio: 375 / 710) {
            transform: translateX(-50%) scale(.7) !important;
            top: 6rem;
          }
        }
      }
  }
}
// ----------------------------------------------------------------------------------------------
// Анимация теслы
.tesla {
  animation: 20s teslaTransition linear infinite;

  .engine-shadow {
    animation: 20s teslaEngineAnimation linear infinite;
  }
}
@keyframes teslaTransition {
  from {
    transform: translate(0) rotate(0);
  }
  10% {
    transform: translate(2.6em, .75em) rotate(0);
  }
  30% {
    transform: translate(8em, .75em) rotate(-25deg);
  }
  80% {
    transform: translate(2.6em, .75em) rotate(0);
  }
  to {
    transform: translate(0) rotate(0);
  }
}
@keyframes teslaEngineAnimation {
  from {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  to {
    opacity: 0;
  }
}
@keyframes teslaControlTooltipRotation {
  from {
    transform: translateX(-50%) rotate(0);
  }
  10% {
    transform: translateX(-50%) rotate(0);
  }
  30% {
    transform: translateX(-50%) rotate(25deg);
  }
  80% {
    transform: translateX(-50%) rotate(0);
  }
  to {
    transform: translateX(-50%) rotate(0);
  }
}
// ----------------------------------------------------------------------------------------------
// Стили на мобилке
.root .planet-wrapper {@include set-adaptive-to-mobile-carousel;}
@media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
  // --------------------
  // Общие стили для всех планеток на главной
  .root.zoom-out .solar-system {
    --planet-mobile-zoom-out-size: 14.125em;
    --planet-neptune-size: var(--planet-mobile-zoom-out-size);
    --planet-jupiter-size: var(--planet-mobile-zoom-out-size);
    --planet-earth-size: var(--planet-mobile-zoom-out-size);
    --planet-mercury-size: var(--planet-mobile-zoom-out-size);
    --planet-sun-size: var(--planet-mobile-zoom-out-size);
    --planet-venus-size: var(--planet-mobile-zoom-out-size);
    --planet-mars-size: var(--planet-mobile-zoom-out-size);
    --planet-saturn-size: 17em;
    --planet-moon-size: var(--planet-mobile-zoom-out-size);
    --planet-uranus-size: 17em;
    --planet-tesla-size: 12em;

    .planets-canvas .planet-wrapper {
      @include set-abs-position(50%, 45%);
    }
  }
  .root .solar-system {
    .planets-canvas .planet-wrapper {
      animation: none;
      display: none;
      // visibility: hidden;
      // transition: .3s ease;

      &.moon { transform: none !important; }
      .planet { z-index: 2; }
      video { z-index: 1; }

      .planet-body, video {
        transition: .2s ease;
      }

      .planet-body {
        z-index: 1;
      }
    }
  }
  // --------------------
  // Общие стили для карточек планет
  .root {
    .card-planet {
      display: none;
      position: absolute;
      // @include size(12.075rem, 15.75rem);
      @include size(17.25em, 15.75em);

      // left: calc(50% - 12.075rem / 2);
      left: calc(50% - 17.25em / 2);
      top: calc(50% - 15.75em / 2 + 1em);

      @include flex(space-between, flex-start);
      border-radius: $card-border-radius;
      padding: $card-padding;
      box-sizing: border-box;
      transition: .3s ease;

      background: rgba(#0D363F, 0.7);
      box-shadow: inset 0px 0px 5px #0F678C;

      opacity: .6;

      .card-header {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        z-index: 3;
        @include flex(flex-start, flex-start, column);

        .planet-name {
          @include font-poppins-40-48-500;
          text-shadow: 0px 2px 5px #381E75;
          color: $color-white-100;
          opacity: 0;
          // transition: .3s ease .3s;
          transition: .3s ease;
        }

        .row {
          @include flex;
          opacity: 0;
          transition: .5s ease;

          .actual-planet-number, .planet-number {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-size: .875rem;
            line-height: 1.5rem;
            font-weight: 400;
            opacity: 1;
          }
          .actual-planet-number {
            color: $color-purple-50;
          }
          .planet-number {
            color: $color-white-100;
          }
          img {
            width: 100%;
            margin: 0 .5rem;
          }
        }
      }

      .card-details {
        position: absolute;
        @include set-abs-position(1.5rem, 13rem);
        @include flex(flex-start, flex-start, column);
        opacity: 0;
        transition: .5s ease;
        z-index: 5;

        .details-icon {
          @include flex;

          .btn { @include size(1.5rem); }
          .eth-value {
            @include flex;
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-size: 1.25rem;
            line-height: 1rem;
            font-weight: 600;

            > strong {
              margin-left: .25rem;
              color: $color-white-100;
            }
            > div {
              margin-left: .25rem;
              font-size: .75rem;
            }
          }
        }
        .details-values {
          color: $color-purple-200;
          @include font-barlow-14-auto-600-4;
        }
      }

      .btn-icon {
        position: absolute;
        width: calc( 100% - 3rem );
        left: 1.5rem;
        bottom: 1.5rem;
        box-sizing: border-box;
        opacity: 0;
        transition: .5s ease;
        z-index: 5;
      }
    }
  }
  // --------------------
  // Расположение планет при активности одной определенной
  // (Та же система что в карусели на десктопе. в файле animation.scss, только уже в цикл переписал... (хуле я раньше не догадался емае))
  $planets-to-swipe:
    // name next prev nextnext prevprev nextnextnext prevprevprev 4next 4prev
    'sun' 'mercury' 'tesla' 'venus' 'neptune' 'earth' 'uranus' 'moon' 'saturn',
    'neptune' 'tesla' 'uranus' 'sun' 'saturn' 'mercury' 'jupiter' 'venus' 'mars',
    'earth' 'moon' 'venus' 'mars' 'mercury' 'jupiter' 'sun' 'saturn' 'tesla',
    'moon' 'mars' 'earth' 'jupiter' 'venus' 'saturn' 'mercury' 'uranus' 'sun',
    'jupiter' 'saturn' 'mars' 'uranus' 'moon' 'neptune' 'earth' 'tesla' 'venus',
    'mercury' 'venus' 'sun' 'earth' 'tesla' 'moon' 'neptune' 'mars' 'uranus',
    'venus' 'earth' 'mercury' 'moon' 'sun' 'mars' 'tesla' 'jupiter' 'neptune',
    'mars' 'jupiter' 'moon' 'saturn' 'earth' 'uranus' 'venus' 'neptune' 'mercury',
    'saturn' 'uranus' 'jupiter' 'neptune' 'mars' 'tesla' 'moon' 'sun' 'earth',
    'uranus' 'neptune' 'saturn' 'tesla' 'jupiter' 'sun' 'mars' 'mercury' 'moon',
    'tesla' 'sun' 'neptune' 'mercury' 'uranus' 'venus' 'saturn' 'earth' 'jupiter';
  //$planets-to-swipe:
  //  // name next prev nextnext prevprev nextnextnext prevprevprev 4next 4prev
  //        'sun' 'mercury' 'neptune' 'venus' 'uranus' 'earth' 'saturn' 'moon' 'jupiter',
  //        'neptune' 'sun' 'uranus' 'mercury' 'saturn' 'venus' 'jupiter' 'earth' 'mars',
  //        'earth' 'moon' 'venus' 'mars' 'mercury' 'jupiter' 'sun' 'saturn' 'neptune',
  //        'moon' 'mars' 'earth' 'jupiter' 'venus' 'saturn' 'mercury' 'uranus' 'sun',
  //        'jupiter' 'saturn' 'mars' 'uranus' 'moon' 'neptune' 'earth' 'sun' 'venus',
  //        'mercury' 'venus' 'sun' 'earth' 'neptune' 'moon' 'uranus' 'mars' 'saturn',
  //        'venus' 'earth' 'mercury' 'moon' 'sun' 'mars' 'neptune' 'jupiter' 'uranus',
  //        'mars' 'jupiter' 'moon' 'saturn' 'earth' 'uranus' 'venus' 'neptune' 'mercury',
  //        'saturn' 'uranus' 'jupiter' 'neptune' 'mars' 'sun' 'moon' 'mercury' 'earth',
  //        'uranus' 'neptune' 'saturn' 'sun' 'jupiter' 'mercury' 'mars' 'venus' 'moon';
  @each $active-planet, $next-planet,$prev-planet, $nextnext-planet,$prevprev-planet, $nextnextnext-planet,$prevprevprev-planet, $fournext-planet,$fourprev-planet in $planets-to-swipe {

    .root.zoom-out.planet-mobile-active-#{$active-planet} {
      .solar-system .planets-canvas {
        .planet-wrapper {
          transition: .2s ease !important;
          .planet-body [class^="planet"] {
            transition: 1s ease;
          }

          &.#{$active-planet}, &.#{$next-planet}, &.#{$prev-planet},
          &.#{$nextnext-planet}, &.#{$prevprev-planet},
          &.#{$nextnextnext-planet}, &.#{$prevprevprev-planet},
          &.#{$fournext-planet}, &.#{$fourprev-planet} {
            display: block;
            // visibility: visible;
            .card-planet {
              display: block;
            }
          }
          &.#{$next-planet} { @include set-abs-position(calc(100% + 1.5em), 50%); }
          &.#{$prev-planet} { @include set-abs-position(calc(-1.5em), 50%); }
          &.#{$nextnext-planet} { @include set-abs-position(calc(150% + 1.5em), 50%); }
          &.#{$prevprev-planet} { @include set-abs-position(calc(-50% - 1.5em), 50%); }

          &.#{$nextnextnext-planet} { @include set-abs-position(calc(200% + 1.5em), 50%); }
          &.#{$prevprevprev-planet} { @include set-abs-position(calc(-100% - 1.5em), 50%); }
          &.#{$fournext-planet} { @include set-abs-position(calc(250% + 1.5em), 50%); }
          &.#{$fourprev-planet} { @include set-abs-position(calc(-150% - 1.5em), 50%); }

          &.#{$next-planet}, &.#{$prev-planet} {
            .planet-body, video, .card-planet {
              transition: .2s ease, .5s opacity .3s linear;
            }
          }

          &.#{$active-planet} {
            // @include set-abs-position(50%, 45%);

            @if ($active-planet == "uranus") {
              --planet-#{$active-planet}-size: 22em;
            } @else if ($active-planet == "saturn") {
              --planet-#{$active-planet}-size: 22em;
            } @else if ($active-planet == "earth") {
              --planet-#{$active-planet}-size: 23em;
            } @else if ($active-planet == "sun") {
              --planet-#{$active-planet}-size: 26em;
            } @else if ($active-planet == "tesla") {
              --planet-#{$active-planet}-size: 19em;
            } @else {
              --planet-#{$active-planet}-size: 21em;
            }

            .planet-body, video {
              @if ($active-planet == "saturn") {
                top: calc(50% - var(--planet-#{$active-planet}-size) / 2);
              } @else if ($active-planet == "earth") {
                top: calc(50% - var(--planet-#{$active-planet}-size) / 2);
              } @else {
                top: calc(50% - var(--planet-#{$active-planet}-size) / 2);
              }
              @if ($active-planet == "earth") {
                left: calc(50% - var(--planet-#{$active-planet}-size) * 0.41);
              } @else {
                left: calc(50% - var(--planet-#{$active-planet}-size) * 0.35);
              }
            }
            .planet {
              @if ($active-planet == "sun") {
                left: calc(50% - var(--planet-#{$active-planet}-size) * var(--planet-scaling-coef) * 0.1);
              } @else {
                left: calc(50% - var(--planet-#{$active-planet}-size) * var(--planet-scaling-coef) * 0.33);
              }
            }
            opacity: 1;

            .card-planet {
              @include size(17.25em, 22.5em);
              @include set-abs-position(calc(50% - 17.25em/ 2), calc(50% - 22.5em / 2 + 2em));
              opacity: 1;
            }
            .card-header .row,.card-header .planet-name, .card-details, .btn-icon {
              opacity: 1;
            }
            .planet-name {
              transition: .3s ease .3s;
            }
          }
          &.#{$next-planet} {
            .card-planet, .planet-body, .planet {
              opacity: .8;
            }
            // video, .planet, .planet-body {animation: 10s clickMe 5s ease infinite;}
            .card-planet { left: calc(50% - 3.5em); }
          }
          &.#{$prev-planet} {
            .card-planet, .planet-body, .planet {
              opacity: .8;
            }
            // video, .planet, .planet-body {animation: 10s clickMe 5s ease infinite;}
            .card-planet { left: calc(50% - 14em); }
          }
        }
      }
    }
  }
}
