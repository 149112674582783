.space-background {
  position: absolute;
  top: 0; left: 0;
  @include size(100vw, 100vh);
  z-index: -3;
  overflow: hidden;
  @include set-adaptive-to-space;

  .background-stars,
  .background-blend-purple,
  .background-dodge, .background-blend-red,
  .background-blend-red-2,
  .star-layer-1, .star-layer-2 {
    @include size(100%);
    position: absolute;
    top: 0; left: 0;
  }

  .background-stars {
    overflow: hidden;
  }

  .background-blend-purple {
    @include size(100%);
    position: absolute;
    top: 0; left: 0;
    background: rgba(#220099, .1);
  }

  .background-blend-red {
    @include size(30em);
    left: calc(50% - 15vw);
    top: calc(50% - 15vw);
    background: #BF0067;
    border-radius: 100%;
    filter: blur(5rem);
    transition: .5s;
    opacity: .1;
    animation: bounce 3s linear infinite;

    @supports (mix-blend-mode: screen) {
      opacity: .2;
      mix-blend-mode: screen;
    }
  }

  @keyframes bounce {
    from {
      transform: scale(1);
    }
    25% {
      transform: scale(.75);
    }
    75% {
      transform: scale(1.25);
    }
    to {
      transform: scale(1);
    }
  }

  .background-blend-red-2  {
    @include size(var(--planet-sun-size) + 1rem);
    @include set-abs-position(calc(50% - 15vw));
    background: #BF1600;
    border-radius: 100%;
    filter: blur(5rem);
    opacity: .1;

    @supports (mix-blend-mode: color-dodge) {
      opacity: .9;
      mix-blend-mode: color-dodge;
    }
  }
}
// ----------------------------------------------------------------------------------------------
// Звездная анимация
.star-layer-1 {
  transition: 2s ease;
}
.star-layer-2 {
  transition: 1s ease;
  path {
    &:nth-child(3n) {
      animation-name: lighten;
      animation-duration: 1s;
      animation-delay: 0;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(4n) {
      animation-name: lighten;
      animation-duration: 2s;
      animation-delay: 2.5s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(5n) {
      animation-name: lighten;
      animation-duration: 2s;
      animation-delay: 2.5s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(5n) {
      animation-name: lighten;
      animation-duration: 2.5s;
      animation-delay: 2.5s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(6n) {
      animation-name: lighten;
      animation-duration: 2.5s;
      animation-delay: 0;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(7n) {
      animation-name: lighten;
      animation-duration: 1.5s;
      animation-delay: 1s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(8n) {
      animation-name: lighten;
      animation-duration: 1.5s;
      animation-delay: 2s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
    &:nth-child(9n) {
      animation-name: lighten;
      animation-duration: 1s;
      animation-delay: 2s;
      animation-timing-function: ease;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes lighten {
  from {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

// ----------------------------------------------------------------------------------------------
// Орбиты и их анимация
.orbits-canvas {
  // Отдельный адаптив орбит и планет
  @include set-adaptive-to-space;
}
// -------------

.orbits-canvas {
  position: absolute;
  @include size(100vw, 100vh);
  overflow: hidden;
  transition: .15s ease;

  &.active-sun .orbit {
    opacity: 1;
  }

  .orbit {
    position: absolute;
    @include set-abs-position(calc(50% - 16.25em / 2 - .1em));
    border-radius: 50%;
    border: 1.75px solid rgba(#5C35AE, .2);
    transition: .5s ease;
    @include size(16.25em);
  }

  .earth-orbit {
    --planet-moon-orbit-left: calc(50% - var(--planet-earth-size) * 0.65 / 2 - var(--planet-moon-orbit-radius) / 2 - 8.875em - 4.5em);
    --planet-moon-orbit-top: calc(50% - var(--planet-earth-size) * 0.65 / 2 - var(--planet-moon-orbit-radius) / 2 - 3.25em);
    position: absolute;
    @include set-abs-position(var(--planet-moon-orbit-left), var(--planet-moon-orbit-top));
    border-radius: 50%;
    border: 1.5px solid rgba(#5C35AE, .2);
    transition: .5s ease;
    @include size(calc(var(--planet-earth-size) * 0.65 + var(--planet-moon-orbit-radius)));
  }

  .tesla-orbit {
    position: absolute;
    --planet-tesla-orbit-left: calc(50% - var(--planet-earth-size) / 2 - 9.5em);
    --planet-tesla-orbit-top: calc(50% - 3em);
    @include set-abs-position(var(--planet-tesla-orbit-left), var(--planet-tesla-orbit-top));
    @include size(31em, auto);
    opacity: .4;
    transition: .5s ease;
  }

  .orbit-2 {
    @include size(21.625em);
    @include set-abs-position(calc(50% - 21.625em / 2));
  }
  .orbit-3 {
    @include size(28.5em);
    @include set-abs-position(calc(50% - 28.5em / 2));
  }
  .orbit-4 {
    @include size(36.5em);
    @include set-abs-position(calc(50% - 36.5em / 2));
  }
  .orbit-5 {
    @include size(43.25em);
    @include set-abs-position(calc(50% - 43.25em / 2));
  }
  .orbit-6 {
    @include size(51.75em);
    @include set-abs-position(calc(50% - 51.75em / 2));
  }
  .orbit-7 {
    @include size(59.25em);
    @include set-abs-position(calc(50% - 59.25em / 2));
  }
  .orbit-8 {
    @include size(68.75em);
    @include set-abs-position(calc(50% - 68.75em / 2));
  }

  &.active-sun {
    .orbit-1 { animation: 5s blindOrbit 0s ease infinite; }
    .orbit-2 { animation: 5s blindOrbit .1s ease infinite; }
    .earth-orbit { animation: 5s blindOrbit .15s ease infinite; }
    .orbit-3 { animation: 5s blindOrbit .2s ease infinite; }
    .orbit-4 { animation: 5s blindOrbit .3s ease infinite; }
    .orbit-5 { animation: 5s blindOrbit .4s ease infinite; }
    .orbit-6 { animation: 5s blindOrbit .5s ease infinite; }
    .orbit-7 { animation: 5s blindOrbit .6s ease infinite; }
    .orbit-8 { animation: 5s blindOrbit .7s ease infinite; }
  }
  &.active-planet-1 .orbit-8 {
    border-color: rgba(#83BCFF, .6);
  }
  &.active-planet-2 .orbit-5 {
    border-color: rgba(#FFB86B, .6);
  }
  &.active-planet-3 .orbit-3 {
    border-color: rgba(#4B7EFF, .6);
  }
  &.active-planet-4 .orbit-1 {
    border-color: rgba(#926048, .6);
  }
  &.active-planet-5 .orbit-2 {
    border-color: rgba(#FAC256, .6);
  }
  &.active-planet-6 .orbit-4 {
    border-color: rgba(#CB5F3D, .6);
  }
  &.active-planet-7 .orbit-6 {
    border-color: rgba(#FFDB5A, .6);
  }
  &.active-planet-8 .orbit-7 {
    border-color: rgba(#92EAF8, .6);
  }
  &.active-planet-9 .earth-orbit {
    border-color: rgba(#FFFFFF, .6);
  }
  &.active-planet-10 .tesla-orbit {
    opacity: 1;
  }
}

@keyframes blindOrbit {
  from {
    border-color: rgba(#5C35AE, .2);
  }
  5% {
    border-color: rgba(#AB83FF, .6);
  }
  10% {
    border-color: rgba(#5C35AE, .2);
  }
  to {
    border-color: rgba(#5C35AE, .2);
  }
}

// ----------------------------------------------------------------------------------------------
// Каметы
.comet {
  position: absolute;
  @include size(1rem, auto);
  opacity: 0;
}

.comet-1 {
  transform: rotateZ(45deg);
  animation: 36s fallingComet1 2s linear infinite forwards;
}

.comet-2 {
  transform: rotateZ(195deg);
  animation: 50s fallingComet2 18s linear infinite forwards;
}

.comet-3 {
  transform: rotateZ(-45deg);
  animation: 20s fallingComet3 linear infinite forwards;
}


@keyframes fallingComet1 {
  from {
    right: -10rem;
    top: 10rem;
    opacity: .75;
  }
  35% {
    right: 390rem;
    top: 410rem;
    opacity: 0;
  }
  to {
    right: -10rem;
    top: 10rem;
    opacity: 0;
  }
}

@keyframes fallingComet2 {
  from {
    left: 0rem;
    bottom: -10rem;
    opacity: .75;
  }
  15% {
    left: 120rem;
    bottom: 400rem;
    opacity: 0;
  }
  to {
    left: 0rem;
    bottom: -10rem;
    opacity: 0;
  }
}


@keyframes fallingComet3 {
  from {
    left: -300rem;
    top: -300rem;
    opacity: 1;
  }
  90% {
    left: 300rem;
    top: 300rem;
    opacity: 0;
  }
  to {
    opacity: 0;
    left: -300rem;
    top: -300rem;
  }
}
