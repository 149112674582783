.root.rotated {
  .rotated-screen {
    display: flex;
    opacity: 1;
  }
}

.rotated-screen {
  @include size(100vw, 100vh);
  position: fixed;
  background: #000;
  @include flex();
  z-index: 1000;
  opacity: 0;
  display: none;
  transition: .5s ease;

  .message {
    @include font-poppins-24-24-600;
    font-weight: 400;
    color: white;
    margin-top: -7rem;
    text-align: center;
  }
}
