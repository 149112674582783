.switch-distance {
  @include flex(center, center, column);
  position: absolute;
  right: 4rem; top: calc(50% - 2.75rem);
  z-index: 1;

  .btn {
    &:not(:first-child) {
      margin-top: .25rem;
    }
  }
}
