$color-white-12: rgba(255, 255, 255, .12);
$color-white-60: rgba(255, 255, 255, .6);
$color-white-100: #FFFFFF;

$color-black-900: #060A11;

// $color-purple-50: #F2EBFF;
// $color-purple-100: #E3D6FF;
// $color-purple-200: #C7ADFF;
// $color-purple-300: #AB83FF;
// $color-purple-400: #8F5AFF;
// $color-purple-500: #7331FF;
// $color-purple-600: #5625BF;
// $color-purple-700: #3A1980;
// $color-purple-800: #1D0C40;
// $color-purple-900: #0C051A;
$color-purple-50: #EAFAFE;
$color-purple-100: #D4F4FC;
$color-purple-200: #A9EAF9;
$color-purple-300: #7EDFF6;
$color-purple-400: #53D5F3;
$color-purple-500: #28CAF0;
$color-purple-600: #1E98B4;
$color-purple-700: #146578;
$color-purple-800: #0A333C;
$color-purple-900: #041418;

$color-green-400: #4AB174;
$color-green-500: #1D9E51;

$color-alert-green: #1A8243;
$color-alert-orange: #C87E1B;
$color-alert-purple: #AA30C5;
$color-alert-red: #D02846;
$color-alert-gray: #787089;

$color-red: #e20030;
$color-red-2: #DD383E;

$color-orange-32: rgba(#BF9425, .32);
$color-orange-400: #FFCFAD;
