@import 'cards-variables';
@import 'purchased-items-card';
@import 'token-items-card';

.card {
  @include flex(space-between, center);
  border-radius: $card-border-radius;
  border: 1px solid $color-purple-600;
  padding: $card-padding;
  box-sizing: border-box;
  transition: .3s ease;

  &.disabled {
    cursor: default;
  }
}

.card-blue {
  background: $card-purchased-items-background;
  box-shadow: $card-purchased-items-shadow;
  cursor: pointer;

  &.hover:hover {
    background: $card-purchased-items-background-hover;
  }
}

.card-gray {
  background: $card-gray-purchased-items-background;
  box-shadow: $card-gray-purchased-items-shadow;
  cursor: pointer;
  border: none;

  &.hover:hover {
    background: $card-gray-purchased-items-background-hover;
  }
}
