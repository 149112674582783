$card-padding: 1.25rem 2rem;
$card-border-radius: .25rem;


$card-purchased-items-background: rgba($color-purple-700, .8);
$card-purchased-items-background-disabled: rgba($color-purple-700, 0);
$card-purchased-items-background-hover: rgba(#48209E, .8);
$card-purchased-items-shadow: inset 0px 0px 4px #5828BE;

$card-gray-purchased-items-background: linear-gradient(180deg, rgba(255, 255, 255, 0.048) 0%, rgba(255, 255, 255, 0.078) 100%);
$card-gray-purchased-items-background-hover: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 100%);
$card-gray-purchased-items-shadow: inset -4.76533e-16px -3px 4px rgba(255, 255, 255, 0.04), inset 4.76533e-16px 3px 3px rgba(255, 255, 255, 0.04);

$card-gray-purchased-items-sold-background: linear-gradient(180deg, rgba(28, 191, 241, 0.08) 0%, rgba(28, 191, 241, 0.08) 29.4%, rgba(28, 191, 241, 0.08) 78.88%);
$card-gray-purchased-items-sold-background-hover: rgba(58, 25, 128, 0.4);
