.preloader-wrapper {
  position: fixed;
  @include set-abs-position(0);
  @include size(100vw, 100vh);
  z-index: 100;
  @include flex(center, center, column);
  transition: .5s ease;
  background: #000;

  .preloader {
    position: relative;
    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      margin-bottom: 5rem;
    }
  }

  .progress {
    @include flex;
    @include font-poppins-20-24-300;
    letter-spacing: .111em;
    color: rgba(255,255,255,.6);
    position: absolute;
    @include set-abs-position(50%, 50%);
    transform: translate(-50%, -50%);

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      font-size: 1rem;
    }
  }
}

.spinner-box {
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &.small {
    width: 2rem;
    height: 2rem;

    .circle-border {
      width: 1rem;
      height: 1rem;
    }
  }

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    @include size(8rem);
  }

  .circle-border {
    width: 8rem;
    height: 8rem;
    padding: 0.1875rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(129.99deg, #28CAF0 14%, rgba(40, 192, 240, 0.81) 34.1%, rgba(40, 202, 240, 0) 65.86%);
    animation: spin .8s linear 0s infinite;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      @include size(6rem);
    }
  }

  .circle-core {
    width: 100%;
    height: 100%;
    background-color: #030010;
    border-radius: 50%;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      background-color: #030010;
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to{
    transform: rotate(359deg);
  }
}
