.earth-continents {
  @include flex(flex-start, center, row);
  position: absolute;
  left: 50%; top: 50%;
  transform: translate(-50%, -50%);
  padding-top: 6rem;
  box-sizing: border-box;
  overflow-x: scroll;
  @include size(100vw, 100vh);
  opacity: 0;
  visibility: hidden;
  transition: .5s ease;
  z-index: 3;

  &::-webkit-scrollbar-track {background: $color-purple-100;}
  &::-webkit-scrollbar {height: .5rem;}
  &::-webkit-scrollbar-thumb {border-radius: 0; background: $color-purple-600;}
  &:hover::-webkit-scrollbar-thumb {background: $color-purple-600;}

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    padding-top: 2rem;
    @include set-abs-position(calc(50% - 100vw / 2), 0);
    transform: translate(0);
  }

  &.switch-checked {
    opacity: 1;
    visibility: visible;

    .card {
      transition: .4s opacity ease;
      opacity: 0;
    }
  }

  .card {
    margin-right: 1rem;
    opacity: 0;
    transition: .4s ease;

    &:nth-child(1) {
      margin-left: calc(50% - 33.5rem);
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        margin-left: 1.5rem;
      }
    }

    &:nth-last-child(-n+2) {
      margin-right: calc(50% - 33.5rem);

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        margin-right: 1.5rem;
      }
    }
  }

  .plug {
    display: none;
    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      display: block;
      opacity: 0;
      visibility: hidden;
      box-sizing: border-box;
      padding: .25rem;
    }
  }
}

$animation-points: 1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20;
@each $number in $animation-points {
  .switch-checked .animate-#{$number} {
    opacity: 1 !important;
    transition: .5s opacity linear #{$number * 0.1}s !important;
  }
}

// адаптив карточек на мобилке
.earth-continents .card {
  @include set-adaptive-to-mobile-earth-countries;

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {

    .token-image {
      @include size(9.125em, 6.25em);
      margin-top: -5.5em;
    }

    .token-number {
      font-size: 1.25em;
    }

    .token-sold {
      @include size(100%, 10.5em);
    }

    .token-name {
      font-size: 1.75em;
      margin-top: .285em; // Xem = .5rem / 1.75
    }

    .token-emission {
      font-size: 1.125em;
      margin-top: .44em; // Xem = .5rem / 1.125
      letter-spacing: .02em;
    }

    .token-price {
      margin: 1em 0;
      padding: 1.875em 0;
      border-right: 1em;

      .btn {
        @include size(1.6875em);
      }

      .token-price-eth {
        margin-top: .625em;

        > b {
          font-size: 1em;
          line-height: 1em;
        }

        > span {
          font-size: .6875em;
          margin-left: .54em; // .375rem = .375 * 1; .6875em = 1; Xem = .375rem / .6875
        }

        .token-price-usd {
          font-size: .875em;
          letter-spacing: .02em;
        }
      }
    }

    .btn-more {
      font-size: 1em;
      line-height: 1em;
      page-break-after: 1em 5.09375em;
      border-radius: 1em;
    }
  }
}
