.planet-info, .country-info {
  .toolbar-wrapper {
    width: 100%;
    @include flex(flex-start,  flex-start, column);
    margin-bottom: 1rem;
  }
  .back-btn {
    @include flex(flex-start, center);
    cursor: pointer;
    opacity: .7;
    transition: .3s ease;
    margin-bottom: .25rem;

    &:hover {
      opacity: 1;
    }

    .btn {
      margin-right: .5rem;
      @include size(1.5rem);
      opacity: 1;
    }

    p {
      text-transform: uppercase;
      font-family: 'Barlow Condensed', sans-serif;
      font-style: normal;
      font-size: .875rem;
      line-height: 1.25rem;
      letter-spacing: .115em;
      font-weight: 600;
    }
  }

  .toolbar {
    @include flex(flex-start,center);
    width: 100%;

    .custom-switch {
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        padding: .5rem;
        margin-left: auto;

        svg {
          @include size(1.75rem);
        }

        p:not(:first-child) {
          margin-left: 1rem;
        }
      }
    }
  }

  .form-elements {
    margin-left: auto;
    opacity: 0;
    visibility: hidden;
    transition: .5s ease;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      margin-top: 1rem;
      width: 100%;
    }

    &.switch-checked {
      opacity: 1;
      visibility: visible;
    }

    form {
      @include flex;
      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        width: 100%;
      }

      .custom-input {
        margin-right: 2rem;
      }

      .custom-input, .custom-search, input {
        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          width: 100%;
          margin: 0;
        }
      }
    }
  }

  .planet-name {
    @include flex(flex-start, center);
    max-width: 100%;

    .name {
      @include font-poppins-56-auto-500;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: -.15rem;
      line-height: normal;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include font-poppins-40-48-500;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }

    > div {
      @include font-poppins-16-16-600;
      text-transform: uppercase;
      margin-left: .5rem;
    }
  }
}


// --------------------
.planet-info, .country-info {
  @include size(66.625rem, 38rem);
  position: absolute;
  @include set-abs-position(calc(50% - 66.625rem / 2), calc(50% - 19rem));
  // opacity: 0;
  visibility: hidden;
  // transition: 1.5s linear;
  // z-index: 3;
  // display: none;

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    @include size(calc(100% - 3rem), 100vh);
    @include set-abs-position(calc(50% - (100% - 3rem) / 2), 0);

    // z-index: -1;
  }

  .details {
    @include flex(space-between, center);
    margin-top: 2.5rem;
    width: 100%;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      position: absolute;
      top: 100%;
      width: calc(100vw - 3rem);
      margin-top: 1.5rem;
      z-index: 1;
    }

    .wrapper {
      @include flex;
    }

    &-arrow {
      opacity: .2;
      flex: 1;
      margin-left: .75rem;
      margin-right: .75rem;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        max-width: 8rem;
      }

      img {
        width: 100%;
      }
    }

    &-values {
      margin-left: .75rem;
      max-width: 100%;
      overflow-x: auto;
      padding-bottom: .25rem;
      &::-webkit-scrollbar-track {background: $color-purple-100;}
      &::-webkit-scrollbar {height: .25rem;}
      &::-webkit-scrollbar-thumb {border-radius: 0; background: $color-purple-600;}
      &:hover::-webkit-scrollbar-thumb {background: $color-purple-600;}

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        padding-bottom: .35rem;
        max-width: 13.5rem;
        margin-left: 0;
      }

      strong {
        @include font-poppins-28-24-500;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          font-size: 1.25rem;
        }
      }

      > div {
        @include font-barlow-18-auto-600-4;
        color: $color-orange-400;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          font-size: 1rem;
        }
      }
    }

    &-icon {
      .btn {
        @include size(4.5rem);
      }
    }
  }

  &.sold {
    .details-buy {
      display: none;
      opacity: 0;
    }

    .details-more {
      display: flex;
      opacity: 1;
    }
  }

  .details-more {
    display: none;
    opacity: 0;

    // @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    //   display: flex;
    //   opacity: .6;
    //   justify-content: flex-start;
    //   margin-top: 1.5rem;
    //   align-items: center;
    //
    //   .btn-box, .details-icon {
    //     @include size(2.5rem);
    //   }
    //
    //   .details-values {
    //     margin-bottom: .5rem;
    //     strong {
    //       font-size: .65rem;
    //       line-height: .5rem;
    //     }
    //
    //     > div {
    //       font-size: .65rem;
    //       line-height: .5rem;
    //     }
    //   }
    // }
  }

  &.show {
    transform: translate(0) scale(1);
    opacity: 1;
    visibility: visible;
    transition: .2s ease;
  }

  .body {
    @include flex(space-between);

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      @include size(100%, 70vh);
      margin-top: 7rem;
      align-items: flex-start;
    }
  }

  .left-content {
    @include flex(space-between, flex-start);
    flex-wrap: wrap;
    width: calc(50% - 1rem);
    opacity: 0;
    transition: .3s ease;
    visibility: hidden;
    position: relative;

    .params {
      width: calc(50% - 1rem);
      border-color: rgba(255,255,255,.24);

      &-label {
        color: $color-orange-400;
        width: calc(100% + .25rem);
      }

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        &:not(:nth-child(3)) {
          margin-top: 1rem;
        }
      }

      &.full-width {
        width: 100%;
        flex-basis: 100%;
        height: auto;
        margin: 2rem 0;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          padding: .75rem 1rem;
          margin-bottom: 0;
          margin-top: 1rem;
          flex-basis: auto;
        }
      }
    }

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      width: 13.5rem;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;

      .params {
        width: 100%;
      }

      .wrapper {
        @include flex(flex-start, flex-start, column);
        margin-bottom: 1.5rem;

        @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
          flex-direction: row;
          margin-bottom: 0;
          justify-content: center;
        }

        .btn-box {
          @include size(2.5rem);
          opacity: .64;

          @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
            @include size(2rem);
          }
        }

        .details-icon {
          width: 100%;
          @include flex(flex-start, center);

          @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
            width: auto;
            margin: auto;
            margin-right: .5rem;
          }
        }

        .eth-value {
          color: $color-white-100 !important;
          margin-left: .5rem;

          strong {
            font-family: 'Poppins', sans-serif;
            font-style: normal;
            font-size: 1.5rem;
            line-height: 2rem;
            font-weight: 600;
          }

          > div {
            letter-spacing: .11em;
            opacity: .64;
            display: inline-block;
            margin-left: .5rem;
          }
        }

        .details-values {
          margin-top: .5rem;
          margin-left: 0;

          > strong {
            @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
              font-size: 1.25rem;
              line-height: 1rem;
            }
          }

          > div {
            @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
              font-size: 1rem;
              line-height: 1rem;
            }
          }

          .usd-value {
            color: $color-white-100 !important;
            font-family: 'Barlow Condensed', sans-serif;
            font-style: normal;
            font-size: 1.25rem;
            font-weight: 600;
            letter-spacing: 0.04em;
          }
        }
      }
    }
  }

  .right-content {
    position: relative;
    width: 50%;
    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      // @include size(auto, 100%);
      // flex: 1;
      position: static;
      width: auto;
    }

    .nft-skeleton {
      height: 175%;
      position: absolute;
      left: 50%; top: -37.5%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transform: translateX(-50%) scale(0);

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include size(20rem, auto);
        @include set-abs-position(calc(50% + 8rem), 20.5rem);
        transform: translate(-50%, -50%) scale(0);
      }

      @supports (mix-blend-mode: lighten) {
        visibility: visible;
        mix-blend-mode: lighten;
      }
    }
  }
}
