@import 'popup-variables';
@import 'popup-wallet';
@import 'popup-token';
@import 'popup-transaction-loader';
@import 'popup-transaction';

.popup {
  position: fixed;
  @include size(100vw, 100vh);
  background-color: rgba(0, 0, 0, 0);
  left: 0;
  top: 0;
  z-index: 10;
  transform: translateY(100vh);
  transition: .5s ease, .3s background-color;
  display: none;
  opacity: 0;

  &.show {
    transform: translateY(0);
    opacity: 1;
    background-color: rgba(0, 0, 0, .8);
    transition: .5s ease, .4s background-color .1s;
  }

  &-container {
    position: absolute;
    width: calc(100% - 6.25rem);
    border-top-left-radius: $popup-border-radius;
    border-top-right-radius: $popup-border-radius;
    background: rgba(#0D363F, 0.7);
    box-shadow: inset 0px 0px 5px #0F678C;
    transform: translateX(-50%);
    padding: $popup-padding;
    box-sizing: border-box;
    bottom: 0;
    left: 50%;
    padding-bottom: 5rem;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      box-shadow: none;
    }
  }

  &-close {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }
}

.popup-background {
  @include size(100vw, 100vh);
  position: fixed;
  top: 0; left: 0;
  opacity: 0;
  transition: .3s ease;
  background-color: rgba(0, 0, 0, .8);
  z-index: 9;
  display: none;

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    z-index: 10;
  }

  &.show {
    opacity: 1;
  }
}
