@import 'tooltips-variables';

.tooltip {
  position: absolute;
  background: $tooltip-background;
  border-radius: $tooltip-border-radius;
  padding: 1.25rem 1.5rem;
  color: $color-purple-600;

  transform: translate(-50%, -2rem);
  left: 50%;
  bottom: calc(100% + .5rem);
  opacity: 0;
  visibility: hidden;
  transition: .5s ease;
  z-index: 100;
  //min-width: 300px;

  .tooltip-row {
    @include flex(space-between, center);

    .title {
      @include font-poppins-24-24-600;
      margin-right: 30px;
    }

    .union {
      @include flex;
      p {
        @include font-poppins-20-14-600;
      }

      svg {
        @include size(1.5rem);
        margin-left: 0.28125rem;

        path {
          fill: $color-purple-600;
        }
      }
    }
  }

  .subtitle {
    @include font-barlow-18-auto-600-4;
    color: $color-green-500;
    margin-top: 0.375rem;
    white-space: pre;
  }

  &::before {
    content: '';
    background: url('../static/icons/tooltip/triangle_new.svg') no-repeat;
    background-size: contain;
    position: absolute;
    @include size(1.25rem, 0.8rem);
    top: calc(100% - 0.06rem);
    left: 50%;
    transform: translateX(-50%);
  }

  &.show {
    transform: translate(-50%, 0rem);
    opacity: 1;
    visibility: visible;
  }
}

.country-tooltip {
  visibility: visible;
  opacity: 1;
  transition: 0s ease;
  @include size(auto,4rem);
  @include flex(stretch, space-between, column);
  background: rgba(255,255,255,.8);
  transform: translate(-50%, -7.5rem);
  pointer-events: none;


  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    display: none;
  }

  &:before {
    opacity: .8;
  }

  .tooltip-row {
    // width: 100%;
    position: relative;
  }


  .title {
    @include font-poppins-16-16-600;
    padding: .5rem;
    white-space: nowrap;
    margin-right: 1rem;
    margin-left: -.5rem;
  }


  &.sold {
  }
}
