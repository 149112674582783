header {
  @include flex();
  position: absolute;
  top: 0; left: 0;
  width: calc(100% - .5rem);
  box-sizing: border-box;
  padding: 2.5rem 3rem;
  padding-bottom: 0;
  z-index: 6;
  transition: opacity .15s ease;

  @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
    z-index: 10;
    padding: 1rem;
  }

  img {
    @include size(8.875rem, auto);
  }

  &.background-blur {
    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      .btn-box {
        display: none !important;
      }
    }
  }
}


.tab-content-wrapper {
  position: relative;
  margin: 0 auto;

  .tabs {
    @include flex(flex-start, center);

    .tab {
      @include size(9.625rem, auto);
      @include font-poppins-16-16-500;
      cursor: pointer;
      text-align: center;
      padding: 1rem;
      box-sizing: border-box;
      opacity: .6;

      &:not(:first-child) {
        margin-left: .25rem;
      }

      &[data-state="active"] {
        opacity: 1;
      }
    }
  }

  .active {
    position: absolute;
    @include size(50%, 100%);
    left: 0; top: 0;
    border-radius: 1rem;
    background: linear-gradient(180deg, rgba(53, 164, 199, 0.6) 0%, rgba(31, 121, 150, 0.6) 100%);
    box-shadow: 1.60837e-15px 26.2667px 52.5333px rgba(41, 19, 20, 0.3345), inset -5.96884e-16px -4px 6px rgba(79, 184, 243, 0.47), inset 5.96884e-16px 4px 4px rgba(78, 170, 255, 0.56);
    transition: .15s ease;
    z-index: -1;
  }
}


// Мобильный компонент
.nav-btn {
  @include flex;
  margin-left: auto;
  @include size(2rem);
  position: relative;

  .bg {
    background: rgba(255,255,255,.8);
    opacity: 0;
    @include size(100%);
    border-radius: .25rem
  }

  [class^="line"] {
    @include size(1.5rem, .125rem);
    min-width: .75rem;
    min-height: .125rem;
    background: white;
    border-radius: .25rem;
  }

  .bg, .line-1, .line-2, .line-3 {
    transition: .3s cubic-bezier(.68,-0.18,.38,1.23);
    position: absolute;
  }

  .line-1 {
    top: .25rem;
  }

  .line-2 {
    top: calc(.25rem + .5rem);
  }

  .line-3 {
    top: calc(.25rem + .5rem * 2);
  }
}

.nav-block {
  position: absolute;
  top: 0; left: 0;
  @include size(100vw, 100vh);
  background: linear-gradient(180deg, rgba(12, 5, 26, 0.9) 0%, rgba(12, 5, 26, 0.9) 71.19%, rgba(12, 5, 26, 0.35) 100%);
  z-index: 9;
  transition: .5s ease;
  box-sizing: border-box;
  padding-top: 5rem;
  padding-bottom: 2.875rem;
  @include flex(center, center, column);
  opacity: 0;
  display: none;

  // &.active {
  //   display: flex;
  //   opacity: 1;
  // }

  nav {
    @include flex(flex-start, center, column);
    margin-top: auto;
    margin-bottom: 5rem;

    .btn {
      margin-bottom: 2rem;
    }

    p {
      text-align: center;
      @include font-poppins-28-24-500;
      padding: 2.75rem 3rem;
      color: $color-white-100;
      transition: .3s ease;

      &:before {
        content: "";
        display: inline-block;
        transition: .3s ease;
      }

      &.active {
        color: $color-purple-400;
        @include flex;

        &::before {
          border: .5rem solid transparent;
          border-left: .75rem solid $color-purple-400;
        }
      }
    }
  }

  .social {
    flex-wrap: wrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 7rem;
    .text {
      flex-basis: 100%;
      text-align: center;
      opacity: 1;
      @include font-poppins-16-16-500;
    }

    p:first-child {
      margin-bottom: 1.5rem;
    }

    a {
      @include size(3rem);

      &:nth-child(2) {
        margin-left: 0;
      }
    }
  }
}

header {
  .logo, .logo-mobile {
    cursor: pointer;
    transition: 1s ease .3s;
  }
  .logo-mobile {
    @include size(3rem);
    margin-left: auto;
    margin-right: -3rem;
  }

  .back-content-btn, [data-close-popup] {
    position: absolute;
    @include size(2rem);
    top: 1.5rem;
    left: 1.5rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      @include size(2.5rem);
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

// -------------------------------------------------
// активность меню
.root.active-menu {
  .nav-block {
    display: flex;
    opacity: 1;
  }

  .nav-btn {
      .bg {
        opacity: 1;
      }

      .line-1 {
        transform: rotateZ(45deg) translate(.4375rem, .4375rem);
        background: $color-purple-800;
      }

      .line-2 {
        transform: rotateZ(-45deg) translate(-0.09375rem, .0625rem);
        background: $color-purple-800;
      }

      .line-3 {
        opacity: 0;
        transform: rotateZ(-45deg) translate(21px, 3px);
        background: $color-purple-800;
      }
  }

  header .btn.mobile-only {
    opacity: 0 !important;
    visibility: hidden !important;
    transition: .5s ease !important;
  }
}
