.popup-transaction-loader {
  .popup-container {
    @include size(auto);
    @include set-abs-position(50%);
    bottom: auto;
    padding: 2.5rem;
    transform: translate(-50%, -50%);
    border-radius: .75rem;
    box-sizing: border-box;

    @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
      top: 40%;
    }

    .message {
      @include font-poppins-16-16-500;
      color: $color-white-100;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        text-align: center;
        white-space: nowrap;
      }
    }

    .transaction-loader {
      @include size(9rem);
      margin: 0 auto;
      position: relative;
      margin-bottom: 2rem;

      @media screen and (max-width: map-get($grid-breakpoints, 'm-sm')) {
        @include size(8rem);
      }

      svg.ellipse {
        transform: scale(1, -1);
        @include size(100%);

        path {
          stroke-width: .25rem;
        }

        .progress {
          stroke: rgba($color-purple-300, 0);
          fill: none;
          stroke-dashoffset:138.5;
          stroke-dasharray:138.5;
          animation: circleProgress 3s ease-in infinite;
        }
      }

      .logo-dark {
        position: absolute;
        @include set-abs-position(50%);
        transform: translate(-50%, -50%);
        @include size(3.5rem);
      }
    }
  }
}

@keyframes circleProgress {
  from {
    stroke-dashoffset: 138.5;
    stroke: rgba($color-purple-300, 0);
  }
  20% {
    stroke: $color-purple-300;
  }
  80% {
    stroke: $color-purple-300;
  }
  to {
    stroke: rgba($color-purple-300, 0);
    stroke-dashoffset: 0;
  }
}
